import React from 'react';
import AuthTemplate from '../components/auth/AuthTemplate';
import NotFoundForm from '../components/common/NotFoundForm';

function NotFoundPage() {
    return (
        <>
            <AuthTemplate>
                <NotFoundForm />
            </AuthTemplate>
        </>       
    );
}
  
export default NotFoundPage;