
import React, { useContext } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ApiContext } from './Context';
import LoginPage from './pages/LoginPage';
import EnterPage from './pages/EnterPage';
import ModifyPage from './pages/ModifyPage';
import FindIdPage from './pages/FindIdPage';
import FindPwdPage from './pages/FindPwdPage';
import NotFoundPage from './pages/NotFoundPage';

import DonationApply from './pages/DonationApply';
import DonationResult from './pages/DonationResult';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faAngleRight, faEdit } from '@fortawesome/free-solid-svg-icons';


library.add(fab, faCheckSquare, faAngleRight, faEdit);
function App() {

    const defaultValue = useContext(ApiContext);
  return (
    <>
    <ApiContext.Provider value={defaultValue}>
        <BrowserRouter>
        <Switch>
            <Route component={NotFoundPage} path="/" exact />
            <Route component={LoginPage} path="/login" />
            <Route component={EnterPage} path="/enter" />
            <Route component={ModifyPage} path="/modify" />
            <Route component={FindIdPage} path="/findId" />
            <Route component={FindPwdPage} path="/findPwd" />
            <Route component={DonationApply} path="/donation/apply" />
            <Route component={DonationResult} path="/donation/result" />
            <Route component={NotFoundPage} path="*" />
        </Switch>   
        </BrowserRouter>     
    </ApiContext.Provider>
    </>
  );
}
export default App;