import React from 'react';


const MailingListItem = ({mailing, onToggle}) => {
    
    const {kind, time, agree} = mailing;

    return (
        <>
                <div className="row border-bottom">
                    <div className="form-text my-2">
                        <div className="mb-0 cfs-15 text-secondary">
                            <div className="row my-0">
                                <div className="col-12 cfs-17">
                                    <label className="form-check-label w-100 d-flex" htmlFor={`agree${kind}`}><div className="col-8"><span className="text-dark cfs-15">{kind==='Info' ? "갓피플 오늘의 묵상 메일링" : "갓피플몰 쇼핑 메일링" } </span></div><div className="col-4 text-end"><span className="cfs-13 lh-base mt-1 pe-1">수신동의 </span><span><input className="form-check-input" type="checkbox" name={`agree${kind}`} id={`agree${kind}`} data-kind={kind} data-agree={agree} checked={agree ==='Y' ? "checked" : ""} onChange={()=>onToggle(kind)} /></span></div></label> 
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 cfs-15 text-secondary">
                            <div className="row my-0">
                                <div className="col-12 text-primary cfs-17">
                                <span className="text-secondary cfs-13">{time}</span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



  
           
        </>
    );

}
export default MailingListItem;

