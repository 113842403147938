import React from 'react';
import Header from '../../pages/Header';
import Footer from '../../pages/Footer';

function FindTemplate({ children }) {
    return (
        
        <div id="gpWrap"> 
            <Header type="find"/>
            <div id="gpContent" role="main">
                <div id="mArticle">
                {children}
                </div>
            </div>
            <Footer/>
        </div>
        
    );
}
export default FindTemplate;
