import React from 'react';
import { Redirect } from "react-router-dom";

function AuthForm() {    
    return (
        <>      
            <Redirect to={{
                pathname: "/login",
                search: "?rurl=/modify",
                state: { referrer: '/modify' }
                }} />
        </>
    );
}
export default AuthForm;
