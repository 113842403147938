import React, { useState, useCallback, useContext } from 'react';
import InputGroup from '../../common/InputGroup';
import ProgressModal from '../../modal/ProgressModal';
import ModalPortal from '../../../ModalPortal';
import axios from "axios";
import * as FCS from '../../../common/js/common_spc';
import { ApiContext } from '../../../Context';

function ChangeMyInfoEmailForm({changeEmailFormClose, changeEmailFormCloseAfterGetData,email_auth_date}) {
    const intialValues = { email:"", user_n: "", authNo:"", fail_msg_email: ""}; 
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유 
    const [modalProgress, setModalProgress] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const closeForm = () => {
        //onReset();
        changeEmailFormClose();
    }
    const closeFormAfterData = () => {
        //onReset();
        changeEmailFormCloseAfterGetData();
    }
    

    const changeEmail = async(auth_idx) => {
        
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'changeEmail',
            new_email: formValues.email,
            auth_idx: auth_idx,
            srvc_id: 'user',
           
        },{ withCredentials: true });
        
        if(response.data.success===true){
            //성공하면.. 리얼 아이디 보이도록
            setFormValues({ ...formValues, info: response.data.info });
           
            console.log('changeEmail')
            setModalProgress(false);
            closeFormAfterData();
        }else{
            //실패시
             
            document.getElementById('fail-msg-email').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('fail_msg_email', formErrors.failMsg); 
            setModalProgress(false);
        }
    }


    const handleBlur = (e) => {
        
        const { name } = e.target;
       
        if (name === 'email'){
            checkValidEmail();
        }
        if (name === 'authNo'){
            checkValidAuthNo();
        }
       
    };

    const handleChange = useCallback(e => {
        //alert('handlechange')
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);

    const changeMyInfoEmailSubmitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        //changeEmail();
        confirmAuthNoClick();
    };
    const changeMyInfoEmailHandleSubmit = (e) => {
       
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
                form.classList.add('was-validated');
                checkValidEmail();
            
            }else{
                e.preventDefault();
                e.stopPropagation();
                
                if(!isValidEmail){
                    checkValidEmail();
                }else{
                    if(!show){
                        document.getElementById('email').classList.add('is-invalid'); 
                        formErrors.email = "인증메일 발송을 눌러주세요.";
                        feedBackErrorMsg('email', formErrors.email);
                    }else{
                        
                        changeMyInfoEmailSubmitForm(e);
                    }
                }
            }
        
    };
    const checkValidEmail = () => {
        let el = document.getElementById('email');
        
        if (!el.value) {
            el.classList.add('is-invalid');
            formErrors.email = "필수 항목입니다";
            setIsValidEmail(false);
         
        } else if (FCS.regex_Mail(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.email = "이메일 형식이 유효하지 않습니다.";
            setIsValidEmail(false);
        } else {
            el.classList.remove('is-invalid');
            el.classList.add('is-valid'); 
            formErrors.email = "";
            setIsValidEmail(true);
        }
        feedBackErrorMsg('email', formErrors.email);
    }
   
    const checkValidAuthNo = () => {
        let el = document.getElementById('authNo');
        if (!el.value) {
            el.classList.add('is-invalid');
            formErrors.authNo = "필수 항목입니다";
        } else {
            el.classList.add('is-valid'); 
            formErrors.authNo = "";
        }
        feedBackErrorMsg('authNo', formErrors.authNo);
    }

    //인증번호관련함수
    const sendMail = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
                task: 'sendMail_AuthNo',
                email: formValues.email,
                akind: 'Modify'
        });
        
        if(response.data.success===true){
            document.getElementById('authNo').classList.remove('is-invalid');
            formErrors.authNo = "메일 확인 후 인증번호를 입력해주세요";
            feedBackErrorMsg('authNo', formErrors.authNo);
            //  setIsValidAuthNumber(true);
        }else{
            document.getElementById('authNo').classList.add('is-invalid');
            formErrors.authNo = response.data.error_msg;
            feedBackErrorMsg('authNo', formErrors.authNo);
        }
    }
    const sendMailClick = () => {
        setFormValues({ ...formValues, authNo: "" });
        sendMail();
    };  
    const onEmailBtnClick = () => {
        if(document.getElementById('email').value.length===0){
            
            document.getElementById('emailFrm').classList.add('was-validated');
            checkValidEmail();

        }else{
            if(isValidEmail){
                setShow(!show);
                //인증메일발송 영역 활성화
                document.getElementById('email').classList.remove('is-invalid');
                formErrors.email = "";
                feedBackErrorMsg('email', formErrors.email);
                //인증메일발송버튼 안보이도록
                document.getElementById('emailAuthBtn').disabled  = true;
                //인증메일발송
                sendMail();
            }
        }
    };
    const confirmAuthNoClick = async() => {
        if(document.getElementById('authNo').value.length===0){
            document.getElementById('authNo').classList.add('is-invalid');
            formErrors.email = "인증번호를 입력해주세요.";
            feedBackErrorMsg('authNo', formErrors.email);
            
        }else{
            setModalProgress(true);
            let el = document.getElementById('authNo');
        // alert(formValues.authNumber)
            //let USER_API = "https://userapi.godpeople.com/";
            const response = await axios.post(USER_API,{
                    task: 'confirmMail_AuthNo',
                    email: formValues.email,
                    auth_no: formValues.authNo,
                    akind: 'Modify'
            });
            
            if(response.data.success===true){
                el.classList.remove('is-invalid');  
                el.classList.add('is-valid');     
                document.getElementById('email').classList.add('is-valid'); 
                formErrors.authNo = "";
            
                changeEmail(response.data.auth_idx);
            }else{
                el.classList.add('is-invalid');       
                formErrors.authNo = "인증번호를 다시 확인해주세요";
                feedBackErrorMsg('authNo', formErrors.authNo);
             //   setIsValidAuthNo(false);
                setModalProgress(false);
            }
        }
    };

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }
    


    return (
        <>
        <form id="emailFrm" className="g-3 needs-validation" noValidate onSubmit={changeMyInfoEmailHandleSubmit}>
        <div className="mb-3">
            
            

            <div className="row mb-1">  
                
                { email_auth_date ? (
                    <>
                    <div>
                        {/*<InputGroup name="email" title="이메일 수정" type="email" placeholder="변경할 이메일" formValue={formValues.email} formError={formErrors.email} handleChange={handleChange} handleBlur={handleBlur} />*/}
                        
                        <label className="col-form-label label_text">이메일 수정</label>
                    </div>
                    <div className="d-flex">    
                        <div className="col-9 pe-1">
                            <input
                            type="email"
                            className="form-control pt-2 pb-2 pe-0"
                            id="email"
                            name="email"
                            placeholder="변경할 이메일" 
                            value={formValues.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required />
                        
                            <div className="invalid-feedback">{formErrors.email}</div>
                        
                        </div>
                        <div className="col-3 mb-0">
                            <button type="button" id="emailAuthBtn" className="btn btn-bd-light w-100 cfs-16 btn-auth" onClick={onEmailBtnClick}>인증</button>
                        </div>
                    </div>
                    </>
                ):(
                    <>
                    <div>
                        {/*<InputGroup name="email" title="이메일 수정" type="email" placeholder="변경할 이메일" formValue={formValues.email} formError={formErrors.email} handleChange={handleChange} handleBlur={handleBlur} />*/}
                        
                        <label className="col-form-label label_text">이메일</label>
                    </div>
                    <div className="d-flex">    
                        <div className="col-9 pe-1">
                            <input
                            type="email"
                            className="form-control pt-2 pb-2 pe-0"
                            id="email"
                            name="email"
                            placeholder="이메일을 입력해주세요" 
                            value={formValues.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required />
                        
                            <div className="invalid-feedback">{formErrors.email}</div>
                        
                        </div>
                        <div className="col-3 mb-0">
                            <button type="button" id="emailAuthBtn" className="btn btn-bd-light w-100 cfs-16 btn-auth" onClick={onEmailBtnClick}>인증</button>
                        </div>
                    </div>
                    </>

                )}
            </div>
            <div className="mb-1">
                <div className="invalid-feedback" id="fail-msg-email">{formErrors.fail_msg_email}</div>
            </div>

            {show ? (
            <div className="mb-2 pt-2 px-3 py-4 pb-2 bg_auth">
                <div className="row mb-1">
                    <p className="mt-2">입력한 메일 주소로 인증번호가 발송되었습니다.<br/>발송된 6자리 인증번호를 입력해 주세요.</p>
                    <InputGroup name="authNo" title="인증번호 입력" type="text" placeholder="인증번호 (6자리)" formValue={formValues.authNo} formError={formErrors.authNo} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                </div>
                <div className="row mb-1">    
                    <div className="col-sm-12">
                        <div className="d-flex justify-content-center">
                            {/*<button type="button" className="btn btn-primary mt-2" onClick={confirmAuthNoClick}>
                            인증확인 </button>*/}
                        </div>
                        <div className="mt-2 mb-1 cfs-12 text-center">인증번호가 오지 않았다면? <u onClick={sendMailClick} role="button">재발송 요청</u></div>
                    </div>
                </div>
            </div>
            ):(
                null
            )}

            <div className="mb-1">    
                <div className="col-sm-12">
                    
                    <div className="d-flex justify-content-center px-4">
                    { email_auth_date ? (
                        <>
                        <div className="col-6 ps-0 pe-1"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-2 mx-auto cfs-18 text-white" onClick={closeForm}>수정취소</button></div>
                        <div className="col-6 pe-0 ps-1"><button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-2 mx-auto cfs-18">수정완료</button></div>
                        </>
                    ):(
                        <>
                        <div className="col-6 ps-0 pe-1"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-2 mx-auto cfs-18 text-white" onClick={closeForm}>취소</button></div>
                        <div className="col-6 pe-0 ps-1"><button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-2 mx-auto cfs-18">완료</button></div>
                        </>
                    )}
                    </div>
                    
                </div>
            </div>
        </div>
        </form>
        
        { modalProgress && ( 
        <ModalPortal>
            <ProgressModal />
        </ModalPortal>
        )}

        </>
    );
}
export default ChangeMyInfoEmailForm;