import React, { useState, useCallback, useEffect, useContext } from 'react';
import Button from '../../components/common/Button';
import InputGroup from '../common/InputGroup';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import axios from "axios";
import * as FCS from '../../common/js/common_spc';
import { ApiContext } from '../../Context';

function LoginForm({id, pwd, user_n}) {
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const intialValues = { id: id, pwd: pwd, new_pw: "", new_pw2: "", user_n: user_n, fail_msg: ""}; 
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [unRest, setUnRest] = useState(false); //휴면해제
    const [modalProgress, setModalProgress] = useState(false);

    const handleLogin = () => {
        if(localStorage.getItem('rurl')){
            window.location.href = "/login?rurl="+localStorage.getItem('rurl');
        }else{
            window.location.href = "/login";
        }
        setTimeout(localStorage.removeItem('rurl'),1000); //로컬스토리지 rurl 삭제
    }
    const recoverFromDormancy = async() => {
        if(formValues.pwd===formValues.new_pw){
            document.getElementById('new_pw').classList.add('is-invalid');
            document.getElementById('new_pw2').classList.add('is-invalid'); 
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.fail_msg = "기존 패스워드와 같네요. 새로운 비밀번호를 입력해주세요.";     
            feedBackErrorMsg('fail_msg', formErrors.fail_msg); 
        }else{
            setModalProgress(true);
            let srvc_id = 'user';
            //let USER_API = "https://userapi.godpeople.com/";
            const response = await axios.post(USER_API,{
                task: 'recoverFromDormancy',
                user_id: formValues.id,
                passwd: formValues.pwd,
                srvc_id: srvc_id,
                user_n: formValues.user_n,
                new_pw: formValues.new_pw,
                route: '4WEB',
            });
            
            if(response.data.success===true){
                //성공하면.. 휴면해제 정상처리 화면 보이도록
                setFormValues({}); //초기화            
                setUnRest(true);
                setModalProgress(false);
            }else{
                //휴면해제요청 실패시
                document.getElementById('new_pw').classList.add('is-invalid');
                document.getElementById('new_pw2').classList.add('is-invalid'); 
                document.getElementById('fail-msg').style.display = 'block';
                formErrors.fail_msg = response.data.error_msg;     
                feedBackErrorMsg('fail_msg', formErrors.fail_msg); 
                setModalProgress(false);
                
            }
        }
    }
    const handleBlur = (e) => {
        const { name } = e.target;
        //const { name, value } = e.target;
      
        if (name === 'new_pw'){
            checkValidNewPwd1();
        }
        if (name === 'new_pw2'){
            checkValidNewPwd2();
        }
    };
    const handleChange = useCallback(e => {
        //alert('handlechange')
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);
    const unRestSubmitForm = (e) => {
        //e.preventDefault();
        //e.stopPropagation();
        recoverFromDormancy();
    }
    const unRestHandleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {           
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');                  
            setFormErrors(validateRest(formValues));    //Error state 값 갱신      
        }else{
            e.preventDefault();
            e.stopPropagation();
            if(formErrors.new_pw==='' && formErrors.new_pw2===''){
                unRestSubmitForm(e);
            }  
            
        }
    };
    const validateRest = (values) => { //휴면해제요청 submit일때만 체크
        let errors = {};
        
        if (!values.new_pw) {
            errors.new_pw = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.new_pw) === false) {
            errors.new_pw = "패스워드 형식이 유효하지 않습니다.";
        } 
        checkValidNewPwd1();

        if (!values.new_pw2) {
            errors.new_pw2 = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.new_pw2) === false) {
            errors.new_pw2 = "패스워드 형식이 유효하지 않습니다.";
        }
        checkValidNewPwd2();
        return errors;
    };
    const checkValidNewPwd1 = () => {
        let el = document.getElementById('new_pw');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.new_pw = "필수 항목입니다";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.new_pw = "패스워드 형식이 유효하지 않습니다";         
        } else {
            el.classList.remove('is-invalid'); 
            formErrors.new_pw = "";     
        }
        feedBackErrorMsg('new_pw', formErrors.new_pw);
    }
    const checkValidNewPwd2 = () => {
        let el = document.getElementById('new_pw2');
        let el_new_pw1 = document.getElementById('new_pw');
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.new_pw2 = "필수 항목입니다";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.new_pw2 = "패스워드 형식이 유효하지 않습니다";         
        } else {
            if(el.value!==el_new_pw1.value){
                el.classList.add('is-invalid');
                formErrors.new_pw2 = "패스워드가 일치하지 않습니다";
            }else{
                el.classList.remove('is-invalid'); 
                formErrors.new_pw2 = "";
            }
        }
        feedBackErrorMsg('new_pw2', formErrors.new_pw2);
    }
    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }

    useEffect(() => {
        if(!unRest){
            if(formErrors.new_pw){
                document.getElementById('new_pw').classList.add('is-invalid');
            }else{
                document.getElementById('new_pw').classList.remove('is-invalid');
            }
            if(formErrors.new_pw2){
                document.getElementById('new_pw2').classList.add('is-invalid');
            }else{
                document.getElementById('new_pw2').classList.remove('is-invalid');
            }   
        }  
    }, [formErrors, unRest]);

    return (
        <>
            { unRest ? (
            <form className="g-3 needs-validation">
                <div className="row">
                    <div className="col-sm-12">
                        <p className="mb-1 cfs-20"><span className="text-primary">휴면 해제 요청</span>이</p>
                        <p className="mb-1 cfs-20"><span className="text-primary">정상 처리</span>되었습니다.</p>
                        <p className="mb-1">로그인 후 갓피플 이용 가능합니다.</p>
                    </div>
                </div>
                <span className="hr mt-3 mb-4"></span>
                <div className="row mb-1">
                    <div className="col-sm-12 form-text">
                        <p className="mb-1">로그인 후 연락처 정보가 최신정보인지 확인 해 주세요</p>
                        <p className="mb-1">휴면기간 중 개정된 약관의 내용은 이용약관 페이지에서 확인 가능합니다.</p>
                    </div>
                </div>

                <button type="button" className="btn w-100 btn-primary bp-3 d-block mt-4 mx-auto cfs-18" onClick={handleLogin}>로그인</button>
            </form>

            ):(
           
            <form className="g-3 needs-validation" onSubmit={unRestHandleSubmit} noValidate>
                <div className="row">
                    <div className="col-sm-12">
                        <p className="mb-1">재방문 해주셔서 감사합니다.</p>
                        <p className="mb-1 cfs-20">현재 회원님은 <span className="text-danger">휴면 상태</span> 입니다.</p>
                        <p className="mb-1">휴면 상태 해제 후 갓피플 이용 가능합니다.</p>
                    </div>
                </div>
                <span className="hr mt-3 mb-4"></span>
                <div className="row mb-1">
                    <div className="col-sm-12 form-text">
                        <p className="mb-1">최근 1년간 갓피플 로그인 기록이 없는 회원님의 아이디를</p>
                        <p className="mb-1">휴면상태 변경 후 회원 개인정보를 안전한 공간에 분리 보관합니다.</p>
                        <p className="mb-1"><span className="text-danger">개인정보 보호법 제29조의6</span></p>
                    </div>
                </div>
                
                <div className="row mb-1">
                    <InputGroup name="new_pw" title="새로운 비밀번호" type="password" placeholder="비밀번호 (8~16자리)" formValue={formValues.new_pw} formError={formErrors.new_pw} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                    
                </div>
                <div className="row mb-1">
                    <InputGroup name="new_pw2" title="새로운 비밀번호 확인" type="password" placeholder="비밀번호 확인" formValue={formValues.new_pw2} formError={formErrors.new_pw2} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                   
                </div>
                <div className="row mb-1">
                    <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                </div>
                <Button title="휴면해제 신청" />
            </form>
            )}
            { modalProgress && ( 
                <ModalPortal>
                    <ProgressModal />
                </ModalPortal>
            )}
        </>

    );
  }
  export default LoginForm;