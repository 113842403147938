import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import '../../scss/_donation.scss';
import { ApiContext } from "../../Context";

function kindName(v) {
    let names = [];
    for( let i=0; i<v.length; i++ )
    {
        let item = v[i]
        if( item === 'all' ) {
            return '전체';
        }

        if( item === 'bible' ) names.push('성경')
        if( item === 'book' ) names.push('도서')
        if( item === 'food' ) names.push('식품')
        if( item === 'goods' ) names.push('용품')
        if( item === 'kids' ) names.push('어린이')
    }

    return names.join(',');
}

const Result = () => {
    const [ result, setResult ] = useState({
        'kinds': [],
        'church_name': '',
        'email': '',
        'phone': '',
        'name': ''
    })
    const context = useContext(ApiContext);
    const USER_API = context.url;
    const USER_DONATION_API = USER_API+"/donation/";

    const getResultSpecialMemberList = async () => {
        try {
            await axios.post(
                USER_DONATION_API,
                {
                    "task": "getResultSpecialMemberList",
                    "cate": "flowing"
                },
                { withCredentials: true}
            )
            .then((res)=> res.data.success === true ? setResult(res.data.result) : alert(res.data.error_msg))
        } catch(err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getResultSpecialMemberList()
    },[])

    return (
        <main className="flowing-apply-container d-flex flex-column w-100 justify-content-center align-items-center p-0" style={{ minHeight: '100vh' }}>
            <div className="flowing-apply-content" style={{ minHeight: 'auto' }}>
                <div className="flowing-title d-flex justify-content-ce nter w-100 flex-column pb-0">
                    <div className="mb-4" style={{ padding: "75px 50px 0 50px" }}>
                        <img src="https://spimage.godpeople.com/gpImage/common/flowing_end.png" />
                    </div>
                    <img src="https://spimage.godpeople.com/gpImage/common/flowing_img.png" />
                </div>
                <div className="flowing-middle pb-0">
                    <h4 className="text-center mb-4 flowing-result-title" style={{ fontWeight: '600', lineHeight: '150%' }}>
                        <span>{result.name} 사역자님!<br /></span>
                        <span>&#60;플로잉 소식받기 신청&#62;이</span>
                        <span>&nbsp;완료되었습니다.</span>
                    </h4>
                    <div style={{ color: "#666" }} className="text-center flowing-result-collapse">
                        <span>하나님께서 우리에게 주신 것들이 흐르고 나누어져,</span>
                        <span>&nbsp;우리의 마음에 감사와 기쁨이 충만하기를 바랍니다.</span>
                        <span>&nbsp;함께 풍성해지는 놀라운 하나님의 은혜를 기대합니다.</span>
                    </div>
                    <div className="mt-4" style={{ border: "2px solid #6351C0", borderRadius: '26px', padding: "24px 33px" }}>
                        <div style={{ fontSize: '17px' }}>휴대폰: {result.phone}</div>
                        <div style={{ fontSize: '17px' }}>이메일: {result.email}</div>
                        <div style={{ fontSize: '17px' }}>교회명: {result.church_name}</div>
                        <div style={{ fontSize: '17px' }}>관심상품: {kindName(result.kinds)}</div>
                    </div>
                    <div className="py-4 text-center" style={{ color: "#6351C0", fontWeight: '600' }}>
                        <div className="flowing-result-collapse">
                            <span>순차적(또는 전체적)으로 문자와 이메일을 통하여</span>
                            <span>&nbsp;사역자님께 플로잉의 소식을 전해드리겠습니다.</span>
                        </div>
                        <div>&nbsp;신청해주셔서 감사합니다.</div>
                    </div>
                </div>
                <a className="w-100 text-center py-4 text-white" style={{ display:'block', background: '#333' }} href="https://www.godpeople.com">
                    <span style={{ fontSize: '20px', fontWeight: '600' }}>갓피플 첫화면으로</span>
                </a>
            </div>
        </main>
    );
}
 
export default Result;