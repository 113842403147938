import React, { useState, useContext } from 'react';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import { ApiContext } from '../../Context';

function ModifyLoginLog() {
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [modalProgress, setModalProgress] = useState(false);
    const [historyItems, setHistoryItems] = useState();

    const historyLogin = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'historyLogin',
        },{ withCredentials: true });
        
        if(response.data.success===true){
            //성공하면.. 마스킹 아이디 보이도록
            setHistoryItems(response.data.list);
            
            setModalProgress(false);
        }else{
        
            setModalProgress(false);
        }
    }

    function handleShow() {
      setFullscreen(true);
      setShow(true);
      historyLogin();
    }
  
    return (
      <>
        <div className="col-12 mb-2 d-flex bg-light px-3 py-1 rounded-1" onClick={() => handleShow()} role="button">
            <div className="py-2 col-10"> 
                <span className="me-2 w-100 text-dialog-title">
                    로그인 기록 
                </span>
            </div>
            <div className="py-2 col-2 text-end"><img src="/img/mic_bl.png" alt="ic_arrow" className="ic_arrow" /></div>
        </div>
    
        <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-50w" centered>
            <Modal.Header closeButton className="mx-2">
                <Modal.Title className="cfs-18">로그인 기록 <p className="mb-0 cfs-13 text-info">로그인 기록은 최근 30일,<br /> 최대 100건까지의 기록이 보여집니다.</p> </Modal.Title>
            </Modal.Header>
            { historyItems &&
            <Modal.Body className="mx-2 my-0">
                {historyItems.map((items, index) => (
                    <div key={index} className="row border-bottom">
                        <ul className="px-0 mt-3">
                        <li className="d-flex align-items-center px-2">
                            <div className="text-secondary d-block col-3 cfs-13">구분</div>
                            <div className="d-block col-9 cfs-15">{items.kind_name}</div>
                        </li>
                        <li className="d-flex align-items-center px-2">
                            <div className="text-secondary d-block col-3 cfs-13">일시</div>
                            <div className="d-block col-9 cfs-15">{items.issue_time}</div>
                        </li>
                        <li className="d-flex align-items-center px-2">
                            <div className="text-secondary d-block col-3 cfs-13">로그인IP</div>
                            <div className="d-block col-9 cfs-15">{items.user_ip}</div>
                        </li>
                        <li className="d-flex align-items-center px-2">
                            <div className="text-secondary d-block col-3 cfs-13">국가</div>
                            <div className="d-block col-9 cfs-15">{items.country}</div>
                        </li>
                        <li className="d-flex align-items-center px-2">
                            <div className="text-secondary d-block col-3 cfs-13">기기</div>
                            <div className="d-block col-9 cfs-15">{items.device}</div>
                        </li>
                        </ul>
                    </div>
                ))}

            </Modal.Body>
            }
        </Modal>
        { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
        )}
      </>
    );
  
}
export default ModifyLoginLog;