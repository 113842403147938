import React, { useState, useEffect } from "react";
import queryString from "query-string";
import AuthTemplate from '../components/auth/AuthTemplate';
import LoginForm from '../components/auth/LoginForm';
import ButtonCloseApp from '../components/common/ButtonCloseApp';


function LoginPage({location}) {
    
    const query = queryString.parse(location.search);
    
    //쿼리스트링(rurl:이전페이지 이동[리다이렉트 주소], appid:앱 로그인 전용) 로컬스토리지 저장
    if(query.rurl !== undefined) localStorage.setItem('rurl',query.rurl); 
    if(query.AppID !== undefined) localStorage.setItem('appid',query.AppID);

    const useTitle = () => {
        const [title, setTitle] = useState(null);
        const updateTitle = () => {
          const htmlTitle = document.querySelector("title");
          htmlTitle.innerHTML = title;
        };
        useEffect(updateTitle, [title]);
        return setTitle;
    };
    const changeTitle = useTitle();

    useEffect(() => {
        changeTitle("갓피플 로그인");
    },[changeTitle]);

    return (
        <>
            <AuthTemplate>
                { localStorage.getItem('appid') && 
                    <ButtonCloseApp />
                }
                <LoginForm />
            </AuthTemplate> 
        </>
    );
}
export default LoginPage;
