import React, { useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FindPwdTabs from '../common/FindPwdTabs';
import FindPwdFormPhone from './FindPwdFormPhone';
import FindPwdFormEmail from './FindPwdFormEmail';
import Card from 'react-bootstrap/Card';


function FindDetailComponent({ select }) {
    return (
      <>
        <FindPwdTabs tab={select} />
        <div className="mt-2">
        {select==='hp' ? (
            <FindPwdFormPhone />
        ):(
            <FindPwdFormEmail />
        )}  
        </div>
      </>
    );
}

  
function FindPwdDetail() {
    // 상태값을 비워놓으면 undefined 상태로, 렌더링 되지 않는다
    // (= 아무것도 출력되지 않음)
    const [select, setSelect] = useState(); 
    const onSelect = (e) =>{
        const page = e.target.dataset.page;
        setSelect(page);
    }

  
    // post 상태값이 없을 때 "로딩 중" 출력. 있을 때 PostDetailComponent 출력
    return (
        <div>
            { !select && 
                <>
                    
                    <FindPwdTabs />
                    <Card className="my-3 mt-4 px-3">
                        <Card.Body>
                            <Card.Title onClick={onSelect} data-page='hp' className="text-primary cfs-18 mb-0" role="button">휴대폰으로 찾기</Card.Title>
                            <Card.Text onClick={onSelect} data-page='hp' className="cfs-15 text-secondary" role="button">
                            등록된 휴대폰을 통해 비밀번호를 찾습니다.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="my-3 px-3">
                        <Card.Body >
                            <Card.Title onClick={onSelect} data-page='email' className="text-primary cfs-18 mb-0" role="button">이메일로 찾기</Card.Title>
                            <Card.Text onClick={onSelect} data-page='email' className="cfs-15 text-secondary" role="button">
                            등록된 이메일을 통해 비밀번호를 찾습니다.
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Breadcrumb className="d-flex flex-column align-items-start cfs-17 py-2 col-12 text-end ms-1">
                        <Breadcrumb.Item href="/findId" className="me-2 w-100 text-info cfs-14">아이디가 기억나지 않는다면? <span className="cfs-14 me-1 text-dialog-title"><u>아이디 찾기</u></span><img src="/img/mic_bl.png" alt="ic_arrow" className="ic_arrow" /></Breadcrumb.Item>
                    </Breadcrumb>
                    
                </>
            }

            { select && <FindDetailComponent select={select} /> }
        </div>
    );
}

function FindPwdForm() {
    return (
        <>
            <FindPwdDetail />           
        </>       
    );
}
  
export default FindPwdForm;
