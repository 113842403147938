import React, { useState, useContext } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import MailingList from './EmailAgree/MailingList';
import axios from "axios";
import * as FCS from '../../common/js/common_spc';
import { ApiContext } from '../../Context';

function ModifyEmailAgree() {
    const context = useContext(ApiContext);
    const USER_API = context.url;
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [modalProgress, setModalProgress] = useState(false);
    const [mailings, setMailings] = useState();
    
    //메일수신동의 기록하기
    const onToggle = (kind) => {
       
        setMailings(mailings.map(mailing => mailing.kind === kind ? { ...mailing, agree: mailing.agree } : mailing));

        mailings.forEach(function (value) {
            if(value.kind === kind && value.agree === 'Y'){
                setMailingN(kind);
            }
            if(value.kind === kind && value.agree === 'N'){
                setMailingY(kind);
            }
        });
       
    };
    const setMailingY = async (kind) => {
        setModalProgress(true); 
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'setMailing',
            kind: kind,
            agree: 'Y'
        },{ withCredentials: true });
       
        if(response.data.success===true){
            setModalProgress(false); 
            listMailing();
        }else{
            setModalProgress(false); 
            alert("error: "+response.data.error_msg);
        }
    };
    const setMailingN = async (kind) => {
        setModalProgress(true); 
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'setMailing',
            kind: kind,
            agree: 'N'
        },{ withCredentials: true });
       
        if(response.data.success===true){
            setModalProgress(false); 
            listMailing();
        }else{
            setModalProgress(false); 
            alert("error: "+response.data.error_msg);
        }
    };
    

    const listMailing = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'listMailing',
        },{ withCredentials: true });
        
        if(response.data.success===true){
            setMailings(response.data.list);
            setModalProgress(false);
        }else{
        
            setModalProgress(false);
        }
    }

    function handleShow() {
      setFullscreen(true);
      setShow(true);
      listMailing();
    }
   
    return (
      <>
       
        <div className="col-12 mb-2 d-flex bg-light px-3 py-1 rounded-1" onClick={() => handleShow()} role="button">
            <div className="py-2 col-10">
            <span className="me-2 w-100 text-dialog-title">
                메일 수신동의 
            </span>               
            </div>
            <div className="py-2 col-2 text-end"><img src="/img/mic_bl.png" alt="ic_arrow" className="ic_arrow" /></div>
        </div>
    
        <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-50w" centered>
            <Modal.Header closeButton className="mx-2">
                <Modal.Title className="cfs-18">메일 수신동의</Modal.Title>
            </Modal.Header>
            { FCS.isEmpty(mailings) === false &&
            <Modal.Body className="mx-2 my-0">
                <MailingList mailings={mailings} onToggle={onToggle} />
                {/*<div className="row mt-5">
                    <div className="col-12 mb-2 d-flex bg-light py-1 rounded-1" role="button">
                        <div className="py-2 col-10">
                            <p className="me-2 w-100 mb-0">
                                쇼핑 알림 서비스
                            </p>
                            <span className="me-2 w-100 d-block cfs-13 text-secondary">
                                도서·저자·출판사 신간 메일링 등
                            </span>
                            <span className="me-2 w-100 d-block cfs-13 text-secondary">
                                쇼핑 상품의 알림을 받아보고 싶다면
                            </span>               
                        </div>
                        <div className="py-2 col-2 text-end align-self-center"><FontAwesomeIcon icon="angle-right" className="fa-lg" /></div>
                    </div>
                </div>*/}
            </Modal.Body>
            }
        </Modal>

        { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
        )}
      </>
    );
  
}
export default ModifyEmailAgree;