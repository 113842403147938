import React, { useState, useCallback, useContext } from 'react';
import Button from '../../components/common/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import axios from "axios";
import * as FCS from '../../common/js/common_spc';
import { ApiContext } from '../../Context';

function ModifyLeave() {
    const context = useContext(ApiContext);
    const USER_API = context.url;
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState({});  //사용자가 입력 필드에 입력 한 데이터를 보유
   // const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [isLeave, setIsLeave] = useState(false);
    const [modalProgress, setModalProgress] = useState(false);
    const [secession, setSecessions] = useState();

    const listSecession = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'getUserInfoForSecession',
        },{ withCredentials: true });
        
        if(response.data.success===true){
            setSecessions(response.data.info);
            setModalProgress(false);
        }else{
        
            setModalProgress(false);
        }
    }

    function handleShow() {
      setFullscreen(true);
      setShow(true);
      listSecession(true);
    }
    const handleChange = useCallback(e => {
        const { name, value, checked } = e.target;
        if(name==='agree'){
            setFormValues({ ...formValues, [name]: checked });    
        }else{
            setFormValues({ ...formValues, [name]: value });
        }
    }, [formValues]);
    const doSecession = async() => {
        setModalProgress(true);
       
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'doSecession',
              
        },{ withCredentials: true });
        
        if(response.data.success===true){
            //성공하면.. 탈퇴완료화면 보이도록
            setIsLeave(true);
            setModalProgress(false);
        }else{
            //가입 실패시
            setModalProgress(false);
            alert(response.data.error_msg);
        }
    }
    const handleSubmit = (e) => {
       
        // console.log(formValues)
         const form = e.currentTarget;
                 
         if (form.checkValidity() === false) {           
             e.preventDefault();
             e.stopPropagation();
             form.classList.add('was-validated');
             //alert('no pass!')            
            // setFormErrors(validate(formValues));    //Error state 값 갱신      
            
         }else{
 
            //  const totError = Object.keys(formErrors).map(function(key) {
            //      var cnt = 0;
            //      if(formErrors[key].length>0){
            //          cnt ++;
            //      }
            //      return cnt;
            //  }).reduce((a,b) => a+b, 0);
           
            //  if(totError<1) {
            
                
                doSecession();
                e.preventDefault();
                e.stopPropagation();
             //}      
         }
     };
    
    return (
      <>
        <div className="col-12 mb-2 d-flex px-3 py-1 rounded-1" onClick={() => handleShow()} role="button">
            <div className="py-2 col-12 text-end">
                <span className="me-2 w-100 text-info cfs-14" >갓피플을 더 이상 이용하지 않는다면</span><span className="cfs-15 me-1 text-dialog-title">회원탈퇴</span><img src="/img/mic_bl.png" alt="ic_arrow" className="ic_arrow" />
            </div>
        </div>
        <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-50w" centered>
          <Modal.Header closeButton className="mx-2">
            <Modal.Title className="cfs-18">회원 탈퇴 안내</Modal.Title>
          </Modal.Header>
          { FCS.isEmpty(secession) === false &&
          <Modal.Body className="mx-2 my-0">
            { !isLeave ? (
                <>
            <form className="g-3 needs-validation" noValidate onSubmit={handleSubmit}>
                <div className="row">
                    <div className="px-2 mb-0"><span className="font-weight-bold">갓피플 회원을 탈퇴</span>하시면</div>
                    <ul className="px-0 mt-1 list-group-numbered">
                        <li className="d-flex px-2 py-2">
                            
                            <div className="d-block col-12 cfs-15 ps-1">사용중인 아이디 <span className="text-primary">{secession.user_id}</span>는 재사용 및 복구가 불가능합니다.</div>
                        </li>
                        <li className="d-flex px-2">
                            
                            <div className="d-block col-12 cfs-15  ps-1">갓피플, 갓피플성경, 갓피플몰 등 갓피플 서비스의<br /><span className="text-danger">이용내역 및 저장된 데이타가 삭제</span>됩니다.</div>
                        </li>
                    </ul>
                </div>
                <Card>
                    <Card.Body className="px-4">
                        <div className="row border-bottom">
                        <ul className="px-0">
                            <li className="d-flex align-items-center px-2">
                                <div className="text-secondary d-block col-3 cfs-13">쇼핑적립금</div>
                                <div className="d-block col-9 cfs-15">{secession.Points}원</div>
                            </li>
                            <li className="d-flex align-items-center px-2">
                                <div className="text-secondary d-block col-3 cfs-13">씨앗</div>
                                <div className="d-block col-9 cfs-15">{secession.Cashes}개</div>
                            </li>
                            <li className="d-flex align-items-center px-2">
                                <div className="text-secondary d-block col-3 cfs-13">갓피플성경</div>
                                <div className="d-block col-9 cfs-15">노트, 형광펜, 북마크, 한줄감사, 통독읽기기록, 역본/음원구매내역</div>
                            </li>
                            <li className="d-flex align-items-center px-2">
                                <div className="text-secondary d-block col-3 cfs-13">갓피플</div>
                                <div className="d-block col-9 cfs-15">취업, 성경쓰기 등 서비스 이용 정보</div>
                            </li>
                            </ul>
                        </div>
                        <div className="row">
                            <span className="mt-3 text-secondary d-block col-12 cfs-13 text-danger">단, 댓글,취업공고,이력서,갓피플몰 리뷰 등 일부 게시글은 탈퇴 전 본인이 직접 삭제해야 합니다.<br />* 탈퇴 이후 본인 작성글의 삭제가 불가능 합니다.</span>
                        </div>
                    </Card.Body>
                </Card>
               
                <div className="col-12 my-3">
                    <input className="form-check-input me-2" type="checkbox" id="agree" name="agree" onChange={handleChange} required />
                    <label className="form-check-label cfs-15" htmlFor="agree">안내 사항을 모두 확인했으며 이에 동의 합니다. 
                    </label>
                    
                    <div className="invalid-feedback">
                    - 필수 항목에 모두 동의해주세요
                    </div>
                </div>
                <div className="text-center text-danger my-3">탈퇴 후에는 아이디 {secession.user_id}<br />회원관련 데이타의 복구가 불가능합니다.</div>
                
                {/*<button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-4 mx-auto cfs-18" onClick={handleLeave}>탈퇴하기</button>*/}

                <Button title="탈퇴하기" />
            </form>

                </>
            ):(
                <>
                <div className="row mt-5 px-5 mb-3"><img src="/img/img_biblec.png" alt="bible" /></div>
                <div className="row">
                    <p className="text-center">부족한 부분들<br />저희가 더 많이 노력하겠습니다.</p>
                    <p className="text-center">하나님의 사람들에게<br />유익한 갓피플이 되도록 더 노력하겠습니다.<br />감사합니다.</p>
                </div>
               
         
                <div className="row px-5">
                    <div className="col-6 ps-0 pe-1"><button type="button" className="btn w-100 btn-primary bp-3 d-block mt-4 mx-auto cfs-18 text-white btn-bg-sky" onClick={FCS.LinkGodpeople}>갓피플</button></div>
                    <div className="col-6  pe-0 ps-1"><button type="button" className="btn w-100 btn-primary bp-3 d-block mt-4 mx-auto cfs-18" onClick={FCS.LinkGodpeopleMall}>갓피플몰</button></div>
                </div>

                </>
            )}
            
          </Modal.Body>
        }
        </Modal>
        { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
        )}
      </>
    );
  
}
export default ModifyLeave;