import React from 'react';
import Header from '../../pages/Header';
import Footer from '../../pages/Footer';

function AuthTemplate({ children }) {
    return (
        <div id="gpWrap"> 
            <Header type="main" />
            <div id="gpContent" role="main">
                <div id="mArticle">
                {children}
                </div>
            </div>
            <Footer/>
        </div>
    );
}
export default AuthTemplate;
