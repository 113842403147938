import React from 'react';
import MailingListItem from './MailingListItem';

const MailingList = ({mailings, onToggle}) => {
    
    return (
        <>
        {mailings.map(mailing=>(
            <MailingListItem mailing={mailing} key={mailing.kind} onToggle={onToggle} />
        ))}
        </>
    );
}
export default MailingList;

