import React, { useState, useContext } from 'react';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import axios from "axios";
import * as FCS from '../../common/js/common_spc';
import { ApiContext } from '../../Context';
import NeedToChangePasswd from './checklogin/NeedToChangePasswd';
import NeedToInputEmail from './checklogin/NeedToInputEmail';
import NeedToInputPhone from './checklogin/NeedToInputPhone';

function NeedToDetailComponent({ id, pwd, need_input_email, need_input_phone }) {
    
    return (
      <>
        { need_input_email &&
            <>
            <NeedToInputEmail id={id} pwd={pwd} /> 
            </>
        }
       
        { (!need_input_email && need_input_phone ) && 
            <>
            <NeedToInputPhone id={id} pwd={pwd} /> 
            </>
        }       
      </>
    );
}

function LoginCheckFormNeeds({id, pwd, need_update_pwd, need_change_pwd, need_input_email, need_input_phone, name}) {
    const context = useContext(ApiContext);
	const USER_API = context.url;
	
    const [modifyPasswd, setModifyPasswd] = useState(false);
    const [modalProgress, setModalProgress] = useState(false);
    const extendChangePasswd = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'extendChangePasswd',
            srvc_id: 'user',
           
        },{ withCredentials: true });
        
        if(response.data.success===true){
            setModalProgress(false);
            window.location.href = localStorage.getItem('rurl');
        }
    }
    const onModifyPasswd = () =>{
        setModifyPasswd(true);
    }
    
    return (
        <>
        { need_update_pwd &&
           <>
            { modifyPasswd && <NeedToChangePasswd id={id} pwd={pwd} /> }

            { (need_update_pwd && !modifyPasswd) && 
                <>
                    
                    <div className="row mt-5 px-5 mb-3 text-center"><img src="/img/ic_security.png" className="ic-security" alt="security" /></div>
                    <div className="row">
                        <p className="text-center cfs-17"><span className="text-primary">보안에 취약한<br />비밀번호 입니다!</span></p>
                    </div>
                    <div className="row">
                        <p className="text-center cfs-17">8자리~16자리<br />영문+숫자+특수문자(@,#등)를<br />조합하여 비밀번호를 변경해주세요!</p>
                    </div>
                    <button type="button" className="btn w-100 btn-primary bp-3 d-block mt-4 mx-auto cfs-18" onClick={onModifyPasswd} >지금 비밀번호 변경하기</button>
                    <div className="row mb-1 mt-3">
                        <p className="text-center cfs-14" onClick={FCS.yesLoginAfterRedirect}><u role="button">다음에 변경하기</u></p>
                        <p className="text-center cfs-15 text-info"><span className="text-primary">[안내] 본 안내창은 비밀번호 변경시까지 계속 뜹니다.</span><br />비밀번호는 주기적으로 변경하는 것이 안전합니다.</p>
                    </div>           
                </>
            }
         </>
        }

        { (!need_update_pwd && need_change_pwd) && 
            <>
            { modifyPasswd && <NeedToChangePasswd id={id} pwd={pwd} /> }

            { (need_change_pwd && !modifyPasswd) && 
                <>
                    
                    <div className="row mt-5 px-5 mb-3 text-center"><img src="/img/ic_security.png" className="ic-security" alt="security" /></div>
                    <div className="row">
                        <p className="text-center cfs-17"><span className="text-primary">비밀번호 변경 후<br />6개월이상 지났습니다.</span></p>
                    </div>
                    <div className="row">
                        <p className="text-center cfs-17"><span className="text-primary">{name}</span>님의 <span className="text-primary">정보보호를 위해</span><br /><span className="text-primary">새로운 비밀번호로 변경</span>해주세요.</p>
                    </div>
                    <button type="button" className="btn w-100 btn-primary bp-3 d-block mt-4 mx-auto cfs-18" onClick={onModifyPasswd} >지금 비밀번호 변경하기</button>
                    <div className="row mb-1 mt-3">
                        <p className="text-center cfs-14" onClick={extendChangePasswd}><u role="button">30일 뒤 변경하기</u></p>
                        <p className="text-center cfs-15 text-info">비밀번호는 주기적으로 변경하는 것이 안전합니다.</p>
                    </div>           
                </>
            }
            </>

        }    
            
           
        { !need_update_pwd && !need_change_pwd && <NeedToDetailComponent id={id} pwd={pwd} need_input_email={need_input_email} need_input_phone={need_input_phone} /> }

        { modalProgress && ( 
        <ModalPortal>
            <ProgressModal />
        </ModalPortal>
        )}
        </>
    );
}
  export default LoginCheckFormNeeds;