import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'
import './ProgressModal.css';
const useStylesFacebook = makeStyles(() => ({
    root: {
      position: 'relative',
    },
    bottom: {
      color: '#000',
      opacity:0.7,
      position: 'absolute',
      top: 0,
        bottom:0,
        left:0,
        right: 0,
        margin:'auto',
    },
    top: {
        color: '#fff',
        opacity:0.95,
        animationDuration: '950ms',
        position: 'absolute',
        top: 0,
        bottom:0,
        left:0,
        right: 0,
        margin:'auto',
    },
    circle: {
      strokeLinecap: 'round',
      strokeWidth:2.5,
    },
  }));

const ProgressModal = () => {
    const classes = useStylesFacebook();
    return (
    <div className="ProgressModal">
        <div className="content">
        <CircularProgress 
            variant="determinate"
            className={classes.bottom}
            size={40}
            thickness={4}
            value={100}  
            classes={{
                circle: classes.circle,
            }} />

        <CircularProgress size={40} className={classes.top}
            classes={{
            circle: classes.circle,
            }}  />
        </div>
    </div>
    );
};

export default ProgressModal;
