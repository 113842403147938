import React, { useState, useEffect } from "react";
import FindTemplate from '../components/auth/FindTemplate';
import FindPwdForm from '../components/auth/FindPwdForm';
import ButtonCloseApp from '../components/common/ButtonCloseApp';

function FindPwPage() {
    const useTitle = () => {
        const [title, setTitle] = useState(null);
        const updateTitle = () => {
          const htmlTitle = document.querySelector("title");
          htmlTitle.innerHTML = title;
        };
        useEffect(updateTitle, [title]);
        return setTitle;
    };
    const changeTitle = useTitle();
    useEffect(() => {
        changeTitle("갓피플 비밀번호 찾기");
    },[changeTitle]);

    return (
        <>
            <FindTemplate>
                { localStorage.getItem('appid') && 
                    <ButtonCloseApp />
                }
                <FindPwdForm />
            </FindTemplate>
        </>
    );
}
export default FindPwPage;
