import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiContext } from '../../Context';
import Button from '../../components/common/Button';
import InputGroup from '../common/InputGroup';
import { Link } from 'react-router-dom';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import axios from "axios";
import * as FCS from '../../common/js/common_spc';
import LoginCheckFormDormancy from './LoginCheckFormDormancy';
import LoginCheckFormNeeds from './LoginCheckFormNeeds';
import Advs from '../../pages/Advs';

function LoginForm() {
    const location = useLocation();
	const queryString = location.search;
	const queryParams = new URLSearchParams(queryString);
	const rurl = queryParams.get('rurl');

    const context = useContext(ApiContext);
    const USER_API = context.url;

    const intialValues = { id: "", pwd: "", new_pw: "", new_pw2: "", user_n: "", keeplong: "n", fail_msg: ""}; 
    const [formValues, setFormValues]       = useState(intialValues); 
    const [formErrors, setFormErrors]       = useState({ fail_msg: "", fail_code: ""}); //각 입력 필드에 대한 오류를 보유
    
    const [isLogin, setIsLogin]             = useState(false); //로그인성공여부
    const [isResting, setIsResting]         = useState(false); //휴면상태여부
    const [isNeeds, setIsNeeds]             = useState(false); //6개월 패스워드변경, 이메일 인증+한달, 휴대폰 인증+한달
    const [modalProgress, setModalProgress] = useState(false);
    const [isChecked, setIsChecked]         = useState(false); //로그인상태유지
    
    const handleCheck = () => {
        setIsChecked(!isChecked);
        if(isChecked===true){
            setFormValues({ ...formValues, keeplong: "n" });
        }else{
            setFormValues({ ...formValues, keeplong: "y" });
        }
    }
    const checkLogin4WEB = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'checkLogin4WEB',
            user_id: formValues.id,
            passwd: formValues.pwd,
            keeplong: formValues.keeplong            
        },
        { withCredentials: true });
        
        if(response.data.success===true){
            setIsLogin(true); 
            //로그인 성공 후 rurl 존재하면 리다이렉트, 로그인할때 LoginPage.js 에서 로컬스토리지 저장함
            //로그인 이후 안내화면 4가지 경우 체크
            if(response.data.NeedToUpdatePasswd===false && response.data.NeedToChangePasswd===false && response.data.NeedToInputEmail===false && response.data.NeedToInputPhone===false){
                //리다이렉트 시켜야함
                if(localStorage.getItem('rurl')){
                    FCS.yesLoginAfterRedirect();
                }else{
                    //이전페이지 없으면 갓피플 홈으로 이동
                    FCS.yesLoginAfterRedirectHome();
                }
            }else{
                setIsNeeds(true);
                setFormValues({ ...formValues, update_pwd: response.data.NeedToUpdatePasswd, change_pwd: response.data.NeedToChangePasswd, input_email: response.data.NeedToInputEmail, input_phone: response.data.NeedToInputPhone, name: response.data.name });
            }
            setModalProgress(false);
        }else{
            //가입 실패시
            if(response.data.error_code===7002){
               setFormValues({ ...formValues, user_n: response.data.user_n });
               setIsResting(true);       
            }else{
                document.getElementById('id').classList.add('is-invalid');
                document.getElementById('pwd').classList.add('is-invalid'); 
                document.getElementById('fail-msg').style.display = 'block';
                formErrors.fail_msg = response.data.error_msg;     
                formErrors.fail_code = response.data.error_code;     
                feedBackErrorMsg('fail_msg', formErrors.fail_msg); 
            }
            setModalProgress(false);
        }
    }

    const checkLogin4APP = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'checkLogin4APP',
            user_id: formValues.id,
            passwd: formValues.pwd,
            keeplong: formValues.keeplong            
        },
        { withCredentials: true });
        
        if(response.data.success===true){
            setIsLogin(true); 

            FCS.send_post('SuccessLogin', response.data.user_n, response.data.user_id, response.data.name, response.data.GLOBALVAL, response.data.logtime);
           
            //로그인 성공 후 rurl 존재하면 리다이렉트, 로그인할때 LoginPage.js 에서 로컬스토리지 저장함
            //로그인 이후 안내화면 4가지 경우 체크
            if(response.data.NeedToUpdatePasswd===false && response.data.NeedToChangePasswd===false && response.data.NeedToInputEmail===false && response.data.NeedToInputPhone===false){
                
                

            }else{
                setIsNeeds(true);
               
                setFormValues({ ...formValues, update_pwd: response.data.NeedToUpdatePasswd, change_pwd: response.data.NeedToChangePasswd, input_email: response.data.NeedToInputEmail, input_phone: response.data.NeedToInputPhone, name: response.data.name  });
                
            }
            setModalProgress(false);
        }else{
            //가입 실패시
            if(response.data.error_code===7002){
               setFormValues({ ...formValues, user_n: response.data.user_n });
               setIsResting(true);       
            }else{
                document.getElementById('id').classList.add('is-invalid');
                document.getElementById('pwd').classList.add('is-invalid'); 
                document.getElementById('fail-msg').style.display = 'block';
                formErrors.fail_msg = response.data.error_msg;
                formErrors.fail_code = response.data.error_code;     
                feedBackErrorMsg('fail_msg', formErrors.fail_msg); 
            }
            setModalProgress(false);
        }
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        //const { name, value } = e.target;
        if (name === 'id'){
            checkValidId();
        }
        if (name === 'pwd'){
            checkValidPwd1();
        }
        if (name === 'new_pw'){
            checkValidNewPwd1();
        }
        if (name === 'new_pw2'){
            checkValidNewPwd2();
        }
    };
    const handleChange = useCallback(e => {
        
        const { name, value } = e.target;

        //console.log('handlechange:'+value)

        setFormValues({ ...formValues, [name]: value });
        
    }, [formValues]);
    
    
    const submitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(localStorage.getItem('appid')){
            checkLogin4APP();
        }else{
            checkLogin4WEB();
        }
    };
    const handleSubmit = (e) => {      
        const form = e.currentTarget;
        if (form.checkValidity() === false) {           
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');                   
            setFormErrors(validate(formValues));    //Error state 값 갱신      
        }else{
            submitForm(e);                   
        }
    };
    const validate = (values) => { //submit일때만 체크
        let errors = {};
        //console.log('validate2'+values.id)
        if (!values.id) {
            errors.id = "필수 항목입니다.";
        } else{
            errors.id = "";
        }
        checkValidId();

        if (!values.pwd) {
            errors.pwd = "필수 항목입니다.";
        } else {
            errors.pwd = "";
        }
        checkValidPwd1();

        return errors;
    };
    
    const checkValidId = () => {
        let el = document.getElementById('id');   
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.id = "필수 항목입니다";            
        } else {
            el.classList.add('is-valid'); 
            formErrors.id = "";
        }
        feedBackErrorMsg('id', formErrors.id);
    }
    const checkValidPwd1 = () => {
        let el = document.getElementById('pwd');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.pwd = "필수 항목입니다";
        } else {
            el.classList.add('is-valid'); 
            formErrors.pwd = "";     
        }
        feedBackErrorMsg('pwd', formErrors.pwd);
    }
    const checkValidNewPwd1 = () => {
        let el = document.getElementById('new_pw');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.new_pw = "필수 항목입니다";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.new_pw = "패스워드 형식이 유효하지 않습니다";         
        } else {
            el.classList.remove('is-invalid'); 
            formErrors.new_pw = "";     
        }
        feedBackErrorMsg('new_pw', formErrors.new_pw);
    }
    const checkValidNewPwd2 = () => {
        let el = document.getElementById('new_pw2');
        let el_new_pw1 = document.getElementById('new_pw');
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.new_pw2 = "필수 항목입니다";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.new_pw2 = "패스워드 형식이 유효하지 않습니다";         
        } else {
            if(el.value!==el_new_pw1.value){
                el.classList.add('is-invalid');
                formErrors.new_pw2 = "패스워드가 일치하지 않습니다";
            }else{
                el.classList.remove('is-invalid'); 
                formErrors.new_pw2 = "";
            }
        }
        feedBackErrorMsg('new_pw2', formErrors.new_pw2);
    }

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }

    const handleGuest = () => {
        FCS.LinkMallGuest();

        setTimeout(localStorage.removeItem('rurl'),1000); //로컬스토리지 rurl 삭제
    }

    useEffect(() => {
        if(!isResting){
            if(formErrors.id){
                document.getElementById('id').classList.add('is-invalid');
            }else{
                document.getElementById('id').classList.remove('is-invalid');
            }
            if(formErrors.pwd){
                document.getElementById('pwd').classList.add('is-invalid');
            }else{
                document.getElementById('pwd').classList.remove('is-invalid');
            }   
        }
    }, [formErrors, isResting]);

   
    return (
        <>
        {isResting ? (          
            <LoginCheckFormDormancy id={formValues.id} pwd={formValues.pwd} user_n={formValues.user_n} />
        ):(
            <>
            { isLogin ? (
                <>
                { isNeeds ? (
                    <LoginCheckFormNeeds id={formValues.id} pwd={formValues.pwd} need_update_pwd={formValues.update_pwd} need_change_pwd={formValues.change_pwd} need_input_email={formValues.input_email} need_input_phone={formValues.input_phone} name={formValues.name} />
                ):(
                    <>
                    <ModalPortal>
                        <ProgressModal />
                    </ModalPortal>
                    </>
                ) }
                </>

            ):(
            <>
            
            <form className="g-3 needs-validation frm_login" noValidate onSubmit={handleSubmit}>
        
               
                <div className="row mb-1">
                    <InputGroup name="id" title="아이디" type="text" placeholder="아이디" formValue={formValues.id} formError={formErrors.id} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                   
                </div>
                <div className="row mb-1">
                    <InputGroup name="pwd" title="비밀번호" type="password" placeholder="비밀번호 입력" formValue={formValues.pwd} formError={formErrors.pwd} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                   
                </div>
               
                <div className="form-check ">
                    <input className="form-check-input" type="checkbox" id="keeplong" name="keeplong" value={formValues.keeplong} onChange={handleCheck} checked={isChecked} />
                    <label className="form-check-label" htmlFor="keeplong"><span className="find_text cfs-14 ">로그인 상태 유지</span></label>
                </div>
                {isChecked &&
                    <div>                    
                        <span className="find_text cfs-14 text-info">개인 정보 보호를 위해 개인 기기에서만 이용해주세요. <a href="https://help.godpeople.com/?GO=cs_faq_show&f_no=894" target="_blank"><span className="cfs-14 me-1 text-dialog-title text-info"><u>도움말보기</u></span><img src="/img/mic_bl.png" alt="ic_arrow" className="ic_arrow" /></a></span>
                    </div>
                }
                <div className="row mb-1">
                    <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                    { formErrors.fail_code===7006 &&  <div className="invalid-feedback d-block">- 아이디찾기로 아이디 확인 또는 회원가입 후 이용해주세요.<br />- 5년 동안 접속 기록이 없는 경우 개인정보처리방침에 따라 탈퇴됩니다.</div>}
                </div>
                <Button title="로그인" />

                <div className="row mt-4 text-center">
                    <div className="find_wrap col-12">
                        <Link to="/findId" ><span className="find_text cfs-14 bpx-3">아이디찾기</span></Link>
                        <i className="co_bar"></i>
                        <Link to="/findPwd" ><span className="find_text cfs-14 bpx-3">비밀번호찾기</span></Link>
                        <i className="co_bar"></i>
                        <Link to="/enter" ><span className="find_text cfs-14 bpx-3">회원가입</span></Link>

						{ ( rurl && rurl.includes('mall.godpeople.com')) &&
							<>
							<i className="co_bar"></i>
							<span className="find_text cfs-14 bpx-3 cursor-pointer" onClick={handleGuest}>비회원주문조회</span>
							</>
						}
                    </div>
                </div>
            </form>
            
            <div className="row mt-4 text-center">
            <Advs />
            </div>
            </>
            )}
            </>
        )}

        { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
        )}
        </>

    );
  }
  export default LoginForm;