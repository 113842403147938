import React from 'react';

function InputGroup({ name, title, type, placeholder, formValue, formError, formSuccess, handleChange, handleBlur, required }) {
    return (
      <>
        <label className="col-form-label label_text pb-0">{title}</label>
        
        <div className="col-sm-12 mb-1">
            <input
            type={type}
            className="form-control pt-2 pb-2"
            id={name}
            name={name}
            placeholder={placeholder}
            value={formValue}
            onChange={handleChange}
            onBlur={handleBlur}
            required={required} />
            { formError ? (
                <div className="invalid-feedback">{formError}</div>
            ):(
                <div className="valid-feedback">{formSuccess}</div>
            )}
        </div>
      </>
    );
  }
  
export default InputGroup;
  
