import React, { useState, useCallback, useEffect, useContext } from 'react';
import Button from '../common/Button';
import InputGroup from '../common/InputGroup';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import { Link } from 'react-router-dom';
import axios from "axios";
import ModifyPasswdForm from './ModifyPasswdForm';
import * as FCS from '../../common/js/common_spc';
import { ApiContext } from '../../Context';

function FindIdFormEmail() {
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const intialValues = { name: "", email: "", authNo: "", failMsg: ""}; 
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [formSuccess, setFormSuccess] = useState({email:""});
    const [isMaskedId, setIsMaskedId] = useState(false); //마스킹아이디찾기ok
    const [isAuthEmail, setIsAuthEmail] = useState(false); //메일인증화면ok
    const [isFindId, setIsFindId] = useState(false); //메일인증화면ok
    
    const [isValidEmail, setIsValidEmail] = useState(false);
    //const [isValidAuthNo, setIsValidAuthNo] = useState(false);  
    const [authIdx, setAuthIdx] = useState('');
    const [propsModifyPasswd, setPropsModifyPasswd] = useState({user_n: "", user_id: "", email: "", authidx: "", locate: ""});
    
    const [modifyPasswd, setModifyPasswd] = useState();
    const [currentRadioInfo, setCurrentRadioInfo] = useState();
    const [modalProgress, setModalProgress] = useState(false);

    const onModifyPasswd = (e) =>{
       // alert('비밀번호변경 클릭 currentRadioUid:'+currentRadioUid)
        //아이디 체크되었을때 이벤트발생하도록
        if(!currentRadioInfo){
            //const { page } = e.target.dataset;
            console.log(  propsModifyPasswd );
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = "아이디를 선택해주세요.";     
            feedBackErrorMsg('fail_msg', formErrors.failMsg);
        //$("input[name=site_name]:checked").val() 
                  
        }else{
            setPropsModifyPasswd({user_n: currentRadioInfo.user_n, user_id: currentRadioInfo.user_id, email: currentRadioInfo.email, auth_idx: authIdx, locate: currentRadioInfo.locate}); 
            setModifyPasswd(true); 
            
        }
    }
    

    const handleUidRadioChange = (e) => {
        setCurrentRadioInfo({user_n: e.target.dataset.un, user_id: e.target.dataset.uid, email: formValues.email, locate: e.target.dataset.locate});
    };

    const handleBlur = (e) => {
        
        const { name } = e.target;
        //const { name, value } = e.target;
        if (name === 'name'){
            checkValidName();
        }
        if (name === 'email'){
            checkValidEmail();
        }
        if (name === 'authNo'){
            checkValidAuthNo();
        }
       
    };
    const handleChange = useCallback(e => {
        //alert('handlechange')
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);
    

//     [메일로 인증하고 리얼아이디 찾기]
// - param: {task:'findUserIDWithAuthedEmail',name:,email:,auth_idx:,locate:}
// - user_id:String, enterday:oooo년 o월 o일
    const findUserIDwithAuthedEmail = async(auth_idx) => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'findUserIDwithAuthedEmail',
            name: formValues.name,
            email: formValues.email,
            auth_idx: auth_idx,
           
        });
        
        if(response.data.success===true){
            //성공하면.. 리얼 아이디 보이도록
            setFormValues({ ...formValues, list: response.data.list });
            setIsFindId(true);
            setModalProgress(false);
        }else{
            //실패시
           // document.getElementById('name').classList.add('is-invalid');
           // document.getElementById('email').classList.add('is-invalid'); 
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('failMsg', formErrors.failMsg); 
            setModalProgress(false);
        }
    }
    const findMaskedUserIDwithEmail = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'findMaskedUserIDwithEmail',
            name: formValues.name,
            email: formValues.email,
        });
        
        if(response.data.success===true){
            //성공하면.. 마스킹 아이디 보이도록
            setFormValues({ ...formValues, list: response.data.list });
            setIsMaskedId(true);   
            setModalProgress(false);
        }else{
            //가입 실패시
            document.getElementById('name').classList.add('is-invalid');
            document.getElementById('email').classList.add('is-invalid'); 
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('fail_msg', formErrors.failMsg); 
            setModalProgress(false);
        }
    }
    //마스킹아이디submit
    const submitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        findMaskedUserIDwithEmail();
    };
    const handleSubmit = (e) => {      
        const form = e.currentTarget;
        if (form.checkValidity() === false) {           
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');                   
            setFormErrors(validate(formValues));    //Error state 값 갱신      
        }else{
            submitForm(e);                   
        }
    };
    //리얼아이디submit
    // const findUserIdSubmitForm = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     findUserIDwithAuthedEmail();
    // };
    // const findUserIdHandleSubmit = (e) => {      
    //     const form = e.currentTarget;
    //     if (form.checkValidity() === false) {           
    //         e.preventDefault();
    //         e.stopPropagation();
    //         form.classList.add('was-validated');                   
    //         setFormErrors(validateAuthNo(formValues));    //Error state 값 갱신      
    //     }else{
    //         findUserIdSubmitForm(e);                   
    //     }
    // };
    //인증창submit
    const authEmailHandleSubmit = (e) => {      
        e.preventDefault();
        e.stopPropagation();                  
        setIsAuthEmail(true);
    };

    const validate = (values) => { //submit일때만 체크
        let errors = {};
        if (!values.name) {
            errors.name = "필수 항목입니다.";
        } else{
            errors.name = "";
        }
        checkValidName();

        if (!values.email) {
            errors.email = "필수 항목입니다.";
        } else if (FCS.regex_Mail(values.email) === false) {
            
            errors.email = "이메일 형식이 유효하지 않습니다.";
          
        } else {
            errors.email = "";
        }
        checkValidEmail();

        return errors;
    };
    // const validateAuthNo = (values) => { //submit일때만 체크
    //     let errors = {};
    //     if (!values.authNo) {
    //         errors.authNo = "필수 항목입니다.";
    //     } else{
    //         errors.authNo = "";
    //     }
    //     checkValidAuthNo();

    //     return errors;
    // };
    const checkValidName = () => {
        let el = document.getElementById('name');   
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.name = "필수 항목입니다";            
        } else {
            el.classList.add('is-valid'); 
            formErrors.name = "";
        }
        feedBackErrorMsg('name', formErrors.name);
    }
    const checkValidEmail = () => {
        let el = document.getElementById('email');
        
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.email = "필수 항목입니다";
            setIsValidEmail(false);
         
        } else if (FCS.regex_Mail(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.email = "이메일 형식이 유효하지 않습니다.";
            setIsValidEmail(false);
        } else {
            //api 중복확인
            el.classList.add('is-valid'); 
            formErrors.email = "";
            setIsValidEmail(true);
        }
        feedBackErrorMsg('email', formErrors.email);
    }
    const checkValidAuthNo = () => {
        let el = document.getElementById('authNo');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.authNo = "필수 항목입니다";
        } else {
            el.classList.add('is-valid'); 
            formErrors.authNo = "";     
        }
        feedBackErrorMsg('authNo', formErrors.authNo);
    }
   
    //인증번호관련함수
    const sendMail = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
                task: 'sendMail_AuthNo',
                email: formValues.email,
                akind: 'IDFind'
        });
        
        if(response.data.success===true){
            document.getElementById('authNo').classList.remove('is-invalid');       
            formErrors.authNo = "메일 확인 후 인증번호를 입력해주세요";
            feedBackErrorMsg('authNo', formErrors.authNo);
            //  setIsValidAuthNumber(true);               
        }else{
            document.getElementById('authNo').classList.add('is-invalid');       
            formErrors.authNo = response.data.error_msg;
            feedBackErrorMsg('authNo', formErrors.authNo);
        }
    }
    const sendMailClick = () => {
        setFormValues({ ...formValues, authNo: "" });
        sendMail();
    };  
    const onEmailBtnClick = () => {
        if(isValidEmail){
            setShow(!show);
            //인증메일발송 영역 활성화
            document.getElementById('email').classList.remove('is-invalid');   
            formErrors.email = "";
            feedBackErrorMsg('email', formErrors.email);
            //인증메일발송버튼 안보이도록
            document.getElementById('emailAuthBtn').style.display="none";   
            //인증메일발송
            sendMail();          
        }
    };
    const confirmAuthNoClick = async() => {
        if(document.getElementById('authNo').value.length===0){
            document.getElementById('authNo').classList.add('is-invalid');   
            formErrors.email = "인증번호를 입력해주세요.";
            feedBackErrorMsg('authNo', formErrors.email);
            
        }else{
            setModalProgress(true);
            let el = document.getElementById('authNo');
        // alert(formValues.authNumber)
            //let USER_API = "https://userapi.godpeople.com/";
            const response = await axios.post(USER_API,{
                    task: 'confirmMail_AuthNo',
                    email: formValues.email,
                    auth_no: formValues.authNo,
                    akind: 'IDFind'
            });
            
            if(response.data.success===true){
                el.classList.remove('is-invalid');  
                el.classList.add('is-valid');     
                document.getElementById('email').classList.add('is-valid'); 
                formErrors.authNo = "";
                formSuccess.email = "인증되었습니다"; 
            // feedBackErrorMsg('authNumber', formErrors.authNumber);
                feedBackSuccessMsg('email', formSuccess.email);

               // setIsValidAuthNo(true);               
                setAuthIdx(response.data.auth_idx);
               // setShow(false);
            // document.getElementById('authSuccessMsg').style.display="block";
                findUserIDwithAuthedEmail(response.data.auth_idx);
            }else{
                el.classList.add('is-invalid');       
                formErrors.authNo = "인증번호를 다시 확인해주세요";
                feedBackErrorMsg('authNo', formErrors.authNo);
             //   setIsValidAuthNo(false);
                setModalProgress(false);
            }
        }
    };

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }
    const feedBackSuccessMsg = (values, msg) => {
         setFormSuccess({ ...formSuccess, [values]: msg});
    }
    
    useEffect(() => {
        if(isMaskedId===false){
            if(formErrors.name){
                document.getElementById('name').classList.add('is-invalid');
            }else{
                document.getElementById('name').classList.remove('is-invalid');
            }
            if(formErrors.email){
                document.getElementById('email').classList.add('is-invalid');
            }else{
                document.getElementById('email').classList.remove('is-invalid');
            }   
        }
    }, [formErrors, isMaskedId]);

    return (
    <>
        { modifyPasswd && <ModifyPasswdForm uid={propsModifyPasswd.user_id} auth_idx={propsModifyPasswd.auth_idx} email={propsModifyPasswd.email} user_n={propsModifyPasswd.user_n} locate={propsModifyPasswd.locate} akind="IDFind" /> }

        {!modifyPasswd &&
        <>
        { isFindId ? (
            <>
                <div className="row mt-3">
                    <div className="col-12">
                        <p className="mb-1 cfs-24 text-secondary display-3 fw-normal">입력한 정보로 찾은 아이디</p>
                        <p className="mb-1 cfs-15 text-secondary mt-3">비밀번호가 기억이 안난다면 바로 변경할 수 있습니다.</p>
                    </div>
                </div>           
                <div className="row my-3">
                    <div className="form-text">
                    {formValues.list.map((items, index) => (
                        <div key={index} className="mb-1 cfs-15 text-secondary">
                            <div className="row my-0">
                                <div className="col-12 text-primary cfs-17">
                                <input className="form-check-input" type="radio" name="chk_uid" id={`uid-${index}`} data-uid={`${items.user_id}`} data-un={`${items.user_n}`} data-locate={`${items.locate}`} onChange={handleUidRadioChange} /> <label className="form-check-label ps-1" htmlFor={`uid-${index}`}>{`${items.user_id}`} <span className="text-secondary cfs-15">{` ( ${items.enterday} 가입 )`}
                                    { items.locate === 'Dormancy' &&
                                        <>, 휴면계정</>
                                    }</span></label> 
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                </div>
                <div className="row px-5">
                    <div className="col-5 ps-0 pe-1"><Link to="/login" ><button type="button" className="btn w-100 btn-success bp-3 d-block mt-4 mx-auto cfs-18 text-white">로그인</button></Link></div>
                    <div className="col-7  pe-0 ps-1"><button type="button" className="btn w-100 btn-primary bp-3 d-block mt-4 mx-auto cfs-18" onClick={onModifyPasswd} data-page="email">비밀번호 변경</button></div>
                </div>
            </>
        ):(
            <>
                { isMaskedId ? (
                    <>
                    {isAuthEmail ? (
                        <>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <p className="mb-1 cfs-24 text-secondary display-3 fw-normal">아이디 확인을 위한 메일 인증</p>
                                    <p className="mb-1 cfs-15 text-secondary mt-3">개인정보보호를 위해 정보 일부를 * 표시 하였습니다.</p>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="form-text">
                                {formValues.list.map((items, index) => (
                                    <div key={index} className="mb-1 cfs-15 text-secondary">
                                        <div className="row my-0">
                                            <div className="col-4 text-primary cfs-17 item_id ps-4">
                                                {`${items.masked_user_id}`}
                                            </div>
                                            <div className="col-8 ps-0">
                                                {` ( ${items.enterday} 가입 )`}
                                                { items.locate === 'Dormancy' &&
                                                    <span>, 휴면계정</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <InputGroup name="email" title="이메일" type="email" placeholder="[필수] 이메일 주소 입력" formValue={formValues.email} formError={formErrors.email} formSuccess={formSuccess.email} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                            </div>
                            <div className="row mb-1 px-5">    
                                <div className="col-sm-12">
                                    <div className="d-flex justify-content-center">
                                       
                                            <button type="button" id="emailAuthBtn" className="btn btn-primary mt-2 bpx-5 bp-3" onClick={onEmailBtnClick}>
                                            인증메일 발송
                                            </button>
                                     
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                            </div>

                            {show ? (
                            <div className="mb-2 pt-2 px-3 py-4 pb-2 bg_auth">
                                <div className="row mb-1">
                                    <p className="mt-2">입력한 메일 주소로 인증번호가 발송되었습니다.<br/>발송된 6자리 인증번호를 입력해 주세요.</p>
                                    <InputGroup name="authNo" title="인증번호 입력" type="text" placeholder="인증번호 (6자리)" formValue={formValues.authNo} formError={formErrors.authNo} formSuccess={formSuccess.authNo} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                                </div>
                                <div className="row mb-1">    
                                    <div className="col-sm-12">
                                        <div className="d-flex justify-content-center">
                                            <button type="button" className="btn btn-primary mt-2" onClick={confirmAuthNoClick}>
                                            인증확인
                                            </button>
                                        </div>
                                        <div className="mt-2 mb-1 cfs-12 text-center">인증번호가 오지 않았다면? <u onClick={sendMailClick} role="button">재발송 요청</u></div>
                                    </div>
                                </div>
                            </div>
                            ):(
                                null
                            )}
                        </>                        
                    ):(
                        <form className="g-3 needs-validation" noValidate onSubmit={authEmailHandleSubmit}>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <p className="mb-1 cfs-24 text-secondary display-3 fw-normal">입력한 정보로 찾은 회원님 아이디</p>
                                    <p className="mb-1 cfs-15 text-secondary mt-3">개인정보보호를 위해 정보 일부를 * 표시 하였습니다.</p>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="form-text">
                                    {formValues.list.map((items, index) => (
                                    <div key={index} className="mb-1 cfs-15 text-secondary">
                                        <div className="row my-0">
                                            <div className="col-4 col-sm-3 text-primary cfs-17 item_id ps-4">
                                                {`${items.masked_user_id}`}
                                            </div>
                                            <div className="col-8 col-sm-9 ps-0">
                                                {` ( ${items.enterday} 가입 )`}
                                                { items.locate === 'Dormancy' &&
                                                    <span>, 휴면계정</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                            </div>
                            <div className="row px-5">
                                <div className="col-5 ps-0 pe-1"><Link to="/login" ><button type="button" className="btn w-100 btn-success bp-3 d-block mt-4 mx-auto cfs-18 text-white">로그인</button></Link></div>
                                <div className="col-7  pe-0 ps-1"><Button title="아이디 확인" /></div>
                            </div>
                        </form>
                    )}
                </> 
                ):(
                    <form className="g-3 needs-validation" noValidate onSubmit={handleSubmit}>  
                        <div className="row mb-1">
                            <InputGroup name="name" title="이름" type="text" placeholder="이름" formValue={formValues.name} formError={formErrors.name} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                        </div>
                        <div className="row mb-1">
                            <InputGroup name="email" title="이메일" type="email" placeholder="이메일 주소" formValue={formValues.email} formError={formErrors.email} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                        </div>
                        <div className="row mb-1">
                            <div className="invalid-feedback" id="fail-msg">{formErrors.failMsg}</div>
                        </div>
                        <div className="row px-5">
                            <div className="col-5 ps-0 pe-1"><a href="/findId"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-4 mx-auto cfs-18 text-white">취소</button></a></div>
                            <div className="col-7  pe-0 ps-1"><Button title="다음" /></div>
                        </div>
                    </form>
                )}
            </>
        )}
        </>
        }

        { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
        )}
    </>
    );
}
export default FindIdFormEmail;