import React, { useState, useCallback, useEffect, useContext } from 'react';
import Button from '../common/Button';
import InputGroup from '../common/InputGroup';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import axios from "axios";
import ModifyPasswdForm from './ModifyPasswdForm';
import * as FCS from '../../common/js/common_spc';
import { ApiContext } from '../../Context';

function FindPwdFormPhone() {
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const intialValues = { id: "", phone: "", authNo: "", failMsg: ""}; 
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [formSuccess, setFormSuccess] = useState({phone:""});
    const [isExist, setIsExist] = useState(false); //아이디,휴대폰ok
   
    const [isValidPhone, setIsValidPhone] = useState(false);
    //const [isValidAuthNo, setIsValidAuthNo] = useState(false);  
   // const [authIdx, setAuthIdx] = useState('');
    const [propsModifyPasswd, setPropsModifyPasswd] = useState({user_n: "", user_id: "", phone: "", authidx: "", locate: ""});
    
    const [modifyPasswd, setModifyPasswd] = useState(false);
    const [modalProgress, setModalProgress] = useState(false);
    

    const handleBlur = (e) => {
        
        const { name } = e.target;
        //const { name, value } = e.target;
        if (name === 'id'){
            checkValidId();
        }
        if (name === 'phone'){
            checkValidPhone();
        }
        if (name === 'authNo'){
            checkValidAuthNo();
        }
       
    };
    const handleChange = useCallback(e => {
        //alert('handlechange')
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);
    

//     [휴대폰으로 인증하고 패스워드 찾기]
    const confirmUserIDwithAuthedPhone = async(auth_idx) => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'confirmUserIDwithAuthedPhone',
            user_id: formValues.id,
            phone: formValues.phone,
            auth_idx: auth_idx,
           
        });
        
        if(response.data.success===true){
            //성공하면.. 새로운 패스워드 변경화면 보이도록
            setPropsModifyPasswd({user_n: response.data.user_n, user_id: formValues.id, phone: formValues.phone, auth_idx: auth_idx, locate: response.data.locate});
            setFormValues({ ...formValues, user_n: response.data.user_n, locate: response.data.locate });       
            setModifyPasswd(true); 
            setModalProgress(false);
        }else{
            //실패시
           
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('failMsg', formErrors.failMsg);
            setModalProgress(false); 
        }
    }
    const isExistUserIDwithPhone = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'isExistUserIDwithPhone',
            user_id: formValues.id,
            phone: formValues.phone,
        });
        
        if(response.data.success===true){
            setIsExist(true);    
            setModalProgress(false);        
        }else{
            //가입 실패시
            document.getElementById('id').classList.add('is-invalid');
            document.getElementById('phone').classList.add('is-invalid'); 
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('fail_msg', formErrors.failMsg); 
            setModalProgress(false);
        }
    }
    //비밀번호 찾기 1-1 submit
    const submitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        isExistUserIDwithPhone();
    };
    const handleSubmit = (e) => {      
        const form = e.currentTarget;
        if (form.checkValidity() === false) {           
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');                   
            setFormErrors(validate(formValues));    //Error state 값 갱신      
        }else{
            submitForm(e);                   
        }
    };
    //인증 후 submit
    // const confirmUserIdSubmitForm = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     confirmUserIDwithAuthedPhone();
    // };
    // const confirmUserIdHandleSubmit = (e) => {      
    //     const form = e.currentTarget;
    //     if (form.checkValidity() === false) {           
    //         e.preventDefault();
    //         e.stopPropagation();
    //         form.classList.add('was-validated');                   
    //         setFormErrors(validateAuthNo(formValues));    //Error state 값 갱신      
    //     }else{
    //         confirmUserIdSubmitForm(e);                   
    //     }
    // };
   
    const validate = (values) => { //submit일때만 체크
        let errors = {};
        
        if (!values.id) {
            errors.id = "필수 항목입니다.";
        } else{
            errors.id = "";
        }
        checkValidId();

        if (!values.phone) {
            errors.phone = "필수 항목입니다.";
        } else if (FCS.regex_Phone(values.phone) === false) {
            
            errors.phone = "휴대폰 형식이 유효하지 않습니다.";
          
        } else {
            errors.phone = "";
        }
        checkValidPhone();

        return errors;
    };
    // const validateAuthNo = (values) => { //submit일때만 체크
    //     let errors = {};
    //     if (!values.authNo) {
    //         errors.authNo = "필수 항목입니다.";
    //     } else{
    //         errors.authNo = "";
    //     }
    //     checkValidAuthNo();

    //     return errors;
    // };
   
    const checkValidId = () => {
        let el = document.getElementById('id');   
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.id = "필수 항목입니다";            
        } else {
            el.classList.add('is-valid'); 
            formErrors.id = "";
        }
        feedBackErrorMsg('id', formErrors.id);
    }
    const checkValidPhone = () => {
        let el = document.getElementById('phone');
        
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.phone = "필수 항목입니다";
            setIsValidPhone(false);
         
        } else if (FCS.regex_Phone(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.phone = "휴대폰 형식이 유효하지 않습니다.";
            setIsValidPhone(false);
        } else {
            //api 중복확인
            el.classList.add('is-valid'); 
            formErrors.phone = "";
            setIsValidPhone(true);
        }
        feedBackErrorMsg('phone', formErrors.phone);
    }
    const checkValidAuthNo = () => {
        let el = document.getElementById('authNo');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.authNo = "필수 항목입니다";
        } else {
            el.classList.add('is-valid'); 
            formErrors.authNo = "";     
        }
        feedBackErrorMsg('authNo', formErrors.authNo);
    }
   
    //인증번호관련함수
    const sendSMS = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
                task: 'sendSMS_AuthNo',
                phone: formValues.phone,
                akind: 'PWFind'
        });
        
        if(response.data.success===true){
            document.getElementById('authNo').classList.remove('is-invalid');       
            formErrors.authNo = "문자 확인 후 인증번호를 입력해주세요";
            feedBackErrorMsg('authNo', formErrors.authNo);
            //  setIsValidAuthNumber(true);               
        }else{
            document.getElementById('authNo').classList.add('is-invalid');       
            formErrors.authNo = response.data.error_msg;
            feedBackErrorMsg('authNo', formErrors.authNo);
        }
    }
    const sendSMSClick = () => {
        setFormValues({ ...formValues, authNo: "" });
        sendSMS();
    };  
    const onSMSBtnClick = () => {
        if(isValidPhone){
            setShow(!show);
            //인증메일발송 영역 활성화
            document.getElementById('phone').classList.remove('is-invalid');   
            formErrors.phone = "";
            feedBackErrorMsg('phone', formErrors.phone);
            //인증메일발송버튼 안보이도록
            document.getElementById('phoneAuthBtn').style.display="none";   
            //인증메일발송
            sendSMS();          
        }
    };
    const confirmAuthNoClick = async() => {
        if(document.getElementById('authNo').value.length===0){
            document.getElementById('authNo').classList.add('is-invalid');   
            formErrors.phone = "인증번호를 입력해주세요.";
            feedBackErrorMsg('authNo', formErrors.phone);
            
        }else{

            let el = document.getElementById('authNo');
        // alert(formValues.authNumber)
            //let USER_API = "https://userapi.godpeople.com/";
            const response = await axios.post(USER_API,{
                    task: 'confirmSMS_AuthNo',
                    phone: formValues.phone,
                    auth_no: formValues.authNo,
                    akind: 'PWFind'
            });
            
            if(response.data.success===true){
                el.classList.remove('is-invalid');  
                el.classList.add('is-valid');     
                document.getElementById('phone').classList.add('is-valid'); 
                formErrors.authNo = "";
                formSuccess.phone = "인증되었습니다"; 
            // feedBackErrorMsg('authNumber', formErrors.authNumber);
                feedBackSuccessMsg('phone', formSuccess.phone);

            //    setIsValidAuthNo(true);               
            //    setAuthIdx(response.data.auth_idx);
            //    setShow(false);
            // document.getElementById('authSuccessMsg').style.display="block";
                

                confirmUserIDwithAuthedPhone(response.data.auth_idx);
            }else{
                el.classList.add('is-invalid');       
                formErrors.authNo = "인증번호를 다시 확인해주세요";
                feedBackErrorMsg('authNo', formErrors.authNo);
            //    setIsValidAuthNo(false);
            }
        }
    };

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }
    const feedBackSuccessMsg = (values, msg) => {
         setFormSuccess({ ...formSuccess, [values]: msg});
    }
    
    useEffect(() => {
    
        if(isExist===false){
            if(formErrors.id){
                document.getElementById('id').classList.add('is-invalid');
            }else{
                document.getElementById('id').classList.remove('is-invalid');
            }
            if(formErrors.phone){
                document.getElementById('phone').classList.add('is-invalid');
            }else{
                document.getElementById('phone').classList.remove('is-invalid');
            }   
        }
    }, [formErrors, isExist]);




return (
    
    <>
        { modifyPasswd && <ModifyPasswdForm uid={propsModifyPasswd.user_id} auth_idx={propsModifyPasswd.auth_idx} phone={propsModifyPasswd.phone} user_n={propsModifyPasswd.user_n} locate={propsModifyPasswd.locate} akind="PWFind" /> }

        {!modifyPasswd &&
            <>
            
                    {isExist ? (
                        <>
                            <div className="row mt-3">
                                <div className="col-sm-12">
                                    {/*<p className="mb-1 cfs-20"><span className="text-primary">이메일 인증 후 비밀번호를 수정할 수 있습니다.</span></p>*/}
                                    <p className="mb-1 cfs-24 text-secondary display-3 fw-normal">휴대폰 인증 후 <br />비밀번호를 수정할 수 있습니다.</p>
                                </div>
                            </div>
                          
                            
                            <div className="row mb-1">
                                {/*<InputGroup name="phone" title="휴대폰" type="tel" placeholder="[필수] 휴대폰 번호 입력" formValue={formValues.phone} formError={formErrors.phone} formSuccess={formSuccess.phone} handleChange={handleChange} handleBlur={handleBlur} />*/}
                                <InputGroup name="phone" title="휴대폰" type="tel" placeholder="휴대폰 번호 입력" formValue={formValues.phone} formError={formErrors.phone} formSuccess={formSuccess.phone} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                            </div>
                            <div className="row mb-1 px-5">    
                                <div className="col-sm-12">
                                    <div className="d-flex justify-content-center">
                                        
                                        <button type="button" id="phoneAuthBtn" className="btn btn-primary mt-2 bpx-5 bp-3" onClick={onSMSBtnClick}>
                                        인증번호 발송
                                        </button>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                            </div>

                            {show ? (
                            <div className="mb-2 pt-2 px-3 py-4 pb-2 bg_auth">
                                <div className="row mb-1">
                                    <p className="mt-2">입력한 휴대폰 번호로 인증번호가 발송되었습니다.<br/>발송된 6자리 인증번호를 입력해 주세요.</p>

                                    <InputGroup name="authNo" title="인증번호 입력" type="text" placeholder="인증번호 (6자리)" formValue={formValues.authNo} formError={formErrors.authNo} formSuccess={formSuccess.authNo} handleChange={handleChange} handleBlur={handleBlur} />
                                    
                                </div>
                                <div className="row mb-1">    
                                    <div className="col-sm-12">
                                        <div className="d-flex justify-content-center">
                                            <button type="button" className="btn btn-primary mt-2" onClick={confirmAuthNoClick}>
                                            인증확인
                                            </button>
                                        </div>
                                        <div className="mt-2 mb-1 cfs-12 text-center">인증번호가 오지 않았다면? <u onClick={sendSMSClick} role="button">재발송 요청</u></div>
                                    </div>
                                </div>
                            </div>
                            ):(
                                null
                            )}
                        </>                        
                    
                    ):(
                        <form className="g-3 needs-validation" noValidate onSubmit={handleSubmit}>  
                            <div className="row mb-1">
                                <InputGroup name="id" title="아이디" type="text" placeholder="아이디" formValue={formValues.id} formError={formErrors.id} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                            
                            </div>
                            <div className="row mb-1">
                                {/*<InputGroup name="phone" title="휴대폰" type="tel" placeholder="휴대폰 번호" formValue={formValues.phone} formError={formErrors.phone} handleChange={handleChange} handleBlur={handleBlur} />*/}
                                <InputGroup name="phone" title="휴대폰" type="tel" placeholder="휴대폰 번호 입력" formValue={formValues.phone} formError={formErrors.phone} formSuccess={formSuccess.phone} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                            </div>
                            <div className="row mb-1">
                                <div className="invalid-feedback" id="fail-msg">{formErrors.failMsg}</div>
                            </div>
                            <div className="row px-5">
                                <div className="col-5 ps-0 pe-1"><a href="/findPwd"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-4 mx-auto cfs-18 text-white">취소</button></a></div>
                                <div className="col-7  pe-0 ps-1"><Button title="다음" /></div>
                            </div>
                        </form>
                    )}
                    
                
            </>
        }

        { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
        )}
    </>
   );
}
export default FindPwdFormPhone;
