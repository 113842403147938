export function regex_ID(s)
{
    var reg = /^[a-z0-9][a-z0-9_-]{3,11}$/;
	return reg.test(s);
}

export function regex_PW(s)
{
	var reg = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()\-_=+[\]{}\\|;:'",<.>/?])[a-zA-Z0-9`~!@#$%^&*()\-_=+[\]{}\\|;:'",<.>/?]{8,16}$/;
	return reg.test(s);
}

export function regex_Name(s)
{
	var reg = /^[가-힣a-zA-Z0-9_\-. ]{2,20}$/;
	return reg.test(s);
}

export function regex_Mail(s)
{
	var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return reg.test(s);
}

export function regex_Phone(s)
{
	var t = s.replace(/[^0-9]/g,"");
	var reg = /^(0[15][016789])[0-9]{7,8}$/;
	return reg.test(t);
}

export function hyphen_Phone(s)
{
	var t = s.replace(/[^0-9]/g,"");
	var l = t.length;
	if(l===11)
		t = t.replace(/^([0-9]{3})([0-9]{4})([0-9]{4})$/, "$1-$2-$3");
	else if(l===10)
		t = t.replace(/^([0-9]{3})([0-9]{3})([0-9]{4})$/, "$1-$2-$3");
	else if(l>=8 && l<=9)
		t = t.replace(/^([0-9]{3})([0-9]{4})([0-9]{1,2})$/, "$1-$2-$3");
	else if(l>=4 && l<=7)
		t = t.replace(/^([0-9]{3})([0-9]{1,4})$/, "$1-$2");
	
	return t;
}

export function calcAge(birth)
{
    var date = new Date();
    var year = date.getFullYear();
    var month = (date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10)
        month = '0' + month;
    if (day < 10)
        day = '0' + day;
    var monthDay = month + '' + day;

    birth = birth.replace('-', '').replace('-', '');
    var birthdayy = birth.substr(0, 4);
    var birthdaymd = birth.substr(4, 4);

    var age = monthDay < birthdaymd ? year - birthdayy - 1 : year - birthdayy;
    return age;
}

export function check_Birthday(birth)
{
    
	var age = calcAge(birth);
	if (age < 0) // 미래
		return false;
	else if (age >= 120) // 120세
		return false;
	else if (age < 14) // 14세 미만
		return false;
	else
		return true;
}
export function regex_AuthNo(s)
{
	var reg = /^[0-9]{6}$/;
	return reg.test(s);
}

export function isEmpty(value){ 
    if( value === "" || value === null || value === undefined || ( value !== null && typeof value === "object" && !Object.keys(value).length ) ){
        return true 
    }else{ 
        return false } 
}


export function yesLoginAfterRedirect() {
    const redirectUrl = localStorage.getItem('rurl');

    if(redirectUrl){
        const targetUrl = redirectUrl === 'modify' ? '/modify' : redirectUrl;
        localStorage.removeItem('rurl'); //로컬스토리지 rurl 삭제
        window.location.href = targetUrl;
    }else{
        yesLoginAfterRedirectHome();
    }  
}
export function reloadLogin()
{
    window.location.href = "/login";
}
export function yesLoginAfterRedirectModify()
{
    window.location.href = "/login?rurl=/modify";
}
export function yesLoginAfterRedirectHome()
{
    window.location.href = "https://www.godpeople.com";
}
export function LinkGodpeople(){
    window.location.href = "https://mw.godpeople.com";
}
export function LinkGodpeopleMall(){
    window.location.href = "https://mall.godpeople.com/apps_page/";
}
export function LinkMallGuest(){
    window.location.href = "https://mall.godpeople.com/mypage/auth";

}
export function openPolicyTerms()
{
    window.open('https://user.godpeople.com/policy/policy_terms.html', '_blank');
}
export function openPromise()
{
    window.open('https://member.godpeople.com/mobile/promise_2_0.html', '_blank');
}
export function openAddressbook()
{
    //window.open('https://pay-dev.godpeople.com/kup/addressbook', '_blank');


    window.open("https://pay.godpeople.com/kup/addressbook", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=yes, resizable=0, width=580, height=800, top=30")
}



export function send_post( type, params, params2, params3, params4, params5 )
{    
    var iMessage, aMessage, isIOS, isAndroid;
    var IorA = navigator.userAgent.toLowerCase();
    if(IorA.indexOf("android") !== -1){
        isAndroid = 1;
        isIOS = 0;
    }else if(IorA.match(/iPhone|iPad|iPod/i)){
        isAndroid = 0;
        isIOS = 1;
    }
    if(type==='reCookie'){ //이름수정하기
        iMessage = {mode: 'reCookie'};   
    }else if(type==='Close'){ //App에서 닫기
        iMessage = {func: 'Close'};
    }else if(type==='SuccessLogin'){ //App에서 로그인 
        iMessage = {func: 'SuccessLogin', user_n: params, user_id: params2, name: params3, GLOBALVAL: params4, logtime: params5 };
    }

    aMessage =  JSON.stringify(iMessage);

    if(isAndroid){
        window.BIBLE_Android_interface.postMessage( aMessage );
    }else if(isIOS){
        window.webkit.messageHandlers.reactobserve.postMessage( iMessage );
    }else{
        //alert(params)
    }
}
