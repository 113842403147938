import React, { useEffect, useState, useCallback, useContext } from 'react';
import InputGroup from '../../common/InputGroup';
import ProgressModal from '../../modal/ProgressModal';
import ModalPortal from '../../../ModalPortal';
import axios from "axios";
import * as FCS from '../../../common/js/common_spc';
import { ApiContext } from '../../../Context';

function ChangeMyInfoNameForm({changeNameFormClose, changeNameFormCloseAfterGetData}) {
    const intialValues =  { new_name: "", fail_msg: ""};
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [modalProgress, setModalProgress] = useState(false);
    
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const closeForm = () => {
        changeNameFormClose();
    }
    const closeFormAfterData = () => {
        changeNameFormCloseAfterGetData();
    }
    

    const changeName = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'changeName',
            new_name: formValues.new_name,
            srvc_id: 'user',
           
        },{ withCredentials: true });
        
        if(response.data.success===true){
            setModalProgress(false);
            closeFormAfterData();
            //2021.11.25 성경app 진입시 이름수정 반영하기 위해 추가함
            FCS.send_post('reCookie');
            

        }else{
            //실패시
             
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('fail_msg', formErrors.failMsg); 
            setModalProgress(false);
        }
    }


    const handleBlur = (e) => {
        const { name } = e.target;
        //const { name, value } = e.target;
        if (name === 'name'){
            checkValidName();
        }
       
    };

    const handleChange = useCallback(e => {
        
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);

    const changeMyInfoNameSubmitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        changeName();
        
    };
    const changeMyInfoNameHandleSubmit = (e) => {
        
        const form = e.currentTarget;
        if (form.checkValidity() === false) { 
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');                  
            //setFormErrors(validate(formValues));    //Error state 값 갱신      
            checkValidName();
        }else{
            changeMyInfoNameSubmitForm(e);
        }
        
    };
    {/*
    const validate = (values) => { //휴면해제요청 submit일때만 체크
        let errors = {};
        
        if (!values.new_name) {
            errors.new_name = "필수 항목입니다.";
        } else {
            errors.new_name = "";
        }
        checkValidName();

        return errors;
    };
*/}

    const checkValidName = () => {
        
        let el = document.getElementById('new_name');   
        if (!el.value) {
            el.classList.add('is-invalid');
            formErrors.new_name = "필수 항목입니다.";            
        } else {
            el.classList.remove('is-invalid');
            el.classList.add('is-valid'); 
            formErrors.new_name = "";
        }
        feedBackErrorMsg('new_name', formErrors.new_name);
    }
   
    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }

    useEffect(() => {
        if(formErrors.id){
            document.getElementById('new_name').classList.add('is-invalid');
        }else{
            document.getElementById('new_name').classList.remove('is-invalid');
        }
      
    }, [formErrors]);
   

    return (
        <>
        <form className="g-3 needs-validation" noValidate onSubmit={changeMyInfoNameHandleSubmit}>
        <div className="mb-3">
           
            <InputGroup name="new_name" title="이름 수정" type="text" placeholder="변경할 이름" formValue={formValues.new_name} formError={formErrors.new_name} handleChange={handleChange} handleBlur={handleBlur} required={true} />

            <div className="mb-1">
                <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
            </div>

            <div className="mb-1">    
                <div className="col-sm-12">                    
                    <div className="d-flex justify-content-center px-4">
                        <div className="col-6 ps-0 pe-1"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-2 mx-auto cfs-18 text-white" onClick={closeForm}>수정취소</button></div>
                        <div className="col-6 pe-0 ps-1"><button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-2 mx-auto cfs-18">수정완료</button></div>
                    </div>
                </div>
            </div>
        </div>
        </form>
        
        { modalProgress && ( 
        <ModalPortal>
            <ProgressModal />
        </ModalPortal>
        )}

        </>
    );
}
export default ChangeMyInfoNameForm;