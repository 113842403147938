import React from 'react';
import Result from "../components/donation/result";

function DonationResult() {
    return (
        <>
            <Result />
        </>
    );
}
export default DonationResult;
