import React, { useState, useCallback, useEffect, useContext } from 'react';
import InputGroup from '../../common/InputGroup';
import ProgressModal from '../../modal/ProgressModal';
import ModalPortal from '../../../ModalPortal';
import axios from "axios";
import * as FCS from '../../../common/js/common_spc';
import { ApiContext } from '../../../Context';


function NeedToInputEmail({id, pwd}) {
    const intialValues = { id: "", email: "", authNo: "", failMsg: ""}; 
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [formSuccess, setFormSuccess] = useState({email:""});
    
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isModify, setIsModify] = useState(false);  
   // const [authIdx, setAuthIdx] = useState('');
    const [modalProgress, setModalProgress] = useState(false);

    const context = useContext(ApiContext);
    const USER_API = context.url;

    const handleBlur = (e) => {
        
        const { name } = e.target;
        //const { name, value } = e.target;
    
        if (name === 'email'){
            checkValidEmail();
        }
        if (name === 'authNo'){
            checkValidAuthNo();
        }
       
    };
    const handleChange = useCallback(e => {
        //alert('handlechange')
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);

    const changeEmail = async(auth_idx) => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'changeEmail',
            new_email: formValues.email,
            auth_idx: auth_idx,
            srvc_id: 'user',
           
        },{ withCredentials: true });
        
        if(response.data.success===true){
            //성공하면.. 리얼 아이디 보이도록
            //setFormValues({ ...formValues, list: response.data.list });
            setIsModify(true);
            setModalProgress(false);
        }else{
            //실패시
           // document.getElementById('name').classList.add('is-invalid');
           // document.getElementById('email').classList.add('is-invalid'); 
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('failMsg', formErrors.failMsg); 
            setModalProgress(false);
        }
    }

    const validate = (values) => { //submit일때만 체크
        let errors = {};
       

        if (!values.email) {
            errors.email = "필수 항목입니다.";
        } else if (FCS.regex_Mail(values.email) === false) {
            
            errors.email = "이메일 형식이 유효하지 않습니다.";
          
        } else {
            errors.email = "";
        }
        checkValidEmail();

        return errors;
    };

    const checkValidEmail = () => {
        let el = document.getElementById('email');
        
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.email = "필수 항목입니다";
            setIsValidEmail(false);
         
        } else if (FCS.regex_Mail(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.email = "이메일 형식이 유효하지 않습니다.";
            setIsValidEmail(false);
        } else {
            //api 중복확인
            el.classList.add('is-valid'); 
            formErrors.email = "";
            setIsValidEmail(true);
        }
        feedBackErrorMsg('email', formErrors.email);
    }
    const checkValidAuthNo = () => {
        let el = document.getElementById('authNo');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.authNo = "필수 항목입니다";
        } else {
            el.classList.add('is-valid'); 
            formErrors.authNo = "";     
        }
        feedBackErrorMsg('authNo', formErrors.authNo);
    }
   
    //인증번호관련함수
    const sendMail = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
                task: 'sendMail_AuthNo',
                email: formValues.email,
                akind: 'Modify'
        });
        
        if(response.data.success===true){
            document.getElementById('authNo').classList.remove('is-invalid');       
            formErrors.authNo = "메일 확인 후 인증번호를 입력해주세요";
            feedBackErrorMsg('authNo', formErrors.authNo);
            //  setIsValidAuthNumber(true);               
        }else{
            document.getElementById('authNo').classList.add('is-invalid');       
            formErrors.authNo = response.data.error_msg;
            feedBackErrorMsg('authNo', formErrors.authNo);
        }
    }
    const sendMailClick = () => {
        setFormValues({ ...formValues, authNo: "" });
        sendMail();
    };  
    const onEmailBtnClick = () => {
        setFormErrors(validate(formValues)); //추가됨
        if(isValidEmail){
            setShow(!show);
            //인증메일발송 영역 활성화
            document.getElementById('email').classList.remove('is-invalid');   
            formErrors.email = "";
            feedBackErrorMsg('email', formErrors.email);
            //인증메일발송버튼 안보이도록
            document.getElementById('emailAuthBtn').style.display="none";   
            //인증메일발송
            sendMail();          
        }
    };
    const confirmAuthNoClick = async() => {
        if(document.getElementById('authNo').value.length===0){
            document.getElementById('authNo').classList.add('is-invalid');   
            formErrors.authNo = "인증번호를 입력해주세요.";
            feedBackErrorMsg('authNo', formErrors.authNo);
            
        }else{
            setModalProgress(true);
            let el = document.getElementById('authNo');
        // alert(formValues.authNumber)
            //let USER_API = "https://userapi.godpeople.com/";
            const response = await axios.post(USER_API,{
                    task: 'confirmMail_AuthNo',
                    email: formValues.email,
                    auth_no: formValues.authNo,
                    akind: 'Modify'
            });
            
            if(response.data.success===true){
                el.classList.remove('is-invalid');  
                el.classList.add('is-valid');     
                document.getElementById('email').classList.add('is-valid'); 
                formErrors.authNo = "";
                formSuccess.email = "인증되었습니다"; 
            
                feedBackSuccessMsg('email', formSuccess.email);     
                //setAuthIdx(response.data.auth_idx);
                changeEmail(response.data.auth_idx);
            }else{
                el.classList.add('is-invalid');       
                formErrors.authNo = "인증번호를 다시 확인해주세요";
                feedBackErrorMsg('authNo', formErrors.authNo);
             //   setIsValidAuthNo(false);
                setModalProgress(false);
            }
        }
    };

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }
    const feedBackSuccessMsg = (values, msg) => {
         setFormSuccess({ ...formSuccess, [values]: msg});
    }
    


    // const yesLoginAfterRedirect = () => {
    //     if(localStorage.getItem('rurl')==='/modify'){
    //         window.location.href = "/modify";
    //     }else{
    //         if(localStorage.getItem('rurl')){
                
    //             window.location.href = localStorage.getItem('rurl');
    //         }else{
    //             FCS.yesLoginAfterRedirectHome();
    //         }
    //     }
    //     setTimeout(localStorage.removeItem('rurl'),1000); //로컬스토리지 rurl 삭제
    // }


    useEffect(() => {     
        if(formErrors.email){
            document.getElementById('email').classList.add('is-invalid');
        }else{
            document.getElementById('email').classList.remove('is-invalid');
        }   
        
    }, [formErrors]);

    return (
        <>           
            {!isModify ? (
                <>
                    <div className="row mt-3">
                        <div className="col-12">
                            <p className="mb-1 cfs-24 text-secondary display-3 fw-normal">현재 사용중인 메일주소를<br />등록(인증)해주세요!</p>
                            <p className="mb-1 cfs-15 text-secondary mt-3">등록(인증)한 메일은 회원계정과 관련된 중요알림 및 아이디/비밀번호를 잊어버렸을때 인증 수단으로 사용됩니다.<br />딱 한번만 인증해주시면 됩니다.</p>
                        </div>
                    </div>
                   
                    <div className="row mb-1">
                        <InputGroup name="email" title="이메일" type="email" placeholder="[필수] 이메일 주소 입력" formValue={formValues.email} formError={formErrors.email} formSuccess={formSuccess.email} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                    </div>
                    <div className="row mb-1 px-5">    
                        <div className="col-sm-12" id="emailAuthBtn">
                            


                            <div className="d-grid gap-2 col-6 mx-auto">
                                <button type="button"  className="btn btn-primary mt-2 bpx-5 bp-3" onClick={onEmailBtnClick}>
                                    인증메일 발송
                                </button>

                                <p className="pt-3 text-center cfs-16 text-primary" onClick={FCS.yesLoginAfterRedirect}><u role="button">다음에 하기</u></p>
                            </div>


                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                    </div>

                    {show ? (
                    <div className="mb-2 pt-2 px-3 py-4 pb-2 bg_auth">
                        <div className="row mb-1">
                            <p className="mt-2">입력한 메일 주소로 인증번호가 발송되었습니다.<br/>발송된 6자리 인증번호를 입력해 주세요.</p>
                            <InputGroup name="authNo" title="인증번호 입력" type="text" placeholder="인증번호 (6자리)" formValue={formValues.authNo} formError={formErrors.authNo} formSuccess={formSuccess.authNo} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                        </div>
                        <div className="row mb-1">    
                            <div className="col-sm-12">
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-primary mt-2" onClick={confirmAuthNoClick}>
                                    인증확인
                                    </button>
                                </div>
                                <div className="mt-2 mb-1 cfs-12 text-center">인증번호가 오지 않았다면? <u onClick={sendMailClick} role="button">재발송 요청</u></div>
                            </div>
                        </div>
                    </div>
                    ):(
                        null
                    )}
                </>                        
            ):(
                <>
                    <div className="row mt-3">
                        <div className="col-12">
                            <p className="mb-1 cfs-24 text-secondary text-center display-3 fw-normal">감사합니다.</p>
                            <p className="mb-1 cfs-15 text-secondary text-center mt-3">연락처가 등록되었습니다.</p>
                        </div>
                    </div>
                   
                    <div className="row px-5">
                      
                        <div className="col-12  pe-0 ps-1"><button type="button" className="btn btn-primary bp-3 w-100 d-block mt-4 mx-auto cfs-18" onClick={FCS.yesLoginAfterRedirect}>확인</button></div>
                    </div>
                </>
            )}
    
            { modalProgress && ( 
                <ModalPortal>
                    <ProgressModal />
                </ModalPortal>
            )}
        </>
        );
}
export default NeedToInputEmail;