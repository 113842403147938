import React, { useState, useCallback, useContext } from 'react';
import { ApiContext } from '../../../Context';
import Button from '../../../components/common/Button';
import InputGroup from '../../common/InputGroup';
import ProgressModal from '../../modal/ProgressModal';
import ModalPortal from '../../../ModalPortal';
import axios from "axios";
import * as FCS from '../../../common/js/common_spc';

function NeedToChangePasswd({id, pwd}) {
    const intialValues = { new_pw: "", new_pw2: "", fail_msg: ""}; 
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유

    const [isModify, setIsModify] = useState(false); //휴면해제
    const [modalProgress, setModalProgress] = useState(false);

    const context = useContext(ApiContext);
    const USER_API = context.url;

    
    const changePasswd = async() => {
        //setModalProgress(true);
       // let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'changePasswd',
            passwd: pwd,
            new_pw: formValues.new_pw,
            srvc_id: 'user',
           
        },{ withCredentials: true });
        
        if(response.data.success===true){
            setIsModify(true);
            setModalProgress(false);
          
        }else{
            //실패시
             
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('fail_msg', formErrors.failMsg); 
            setModalProgress(false);
        }
    }


    const handleBlur = (e) => {
        const { name } = e.target;
      
        if (name === 'new_pw'){
            checkValidNewPwd1();
        }
        if (name === 'new_pw2'){
            checkValidNewPwd2();
        }
    };

    const handleChange = useCallback(e => {
        
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);

    const changeMyInfoPwdSubmitForm = (e) => {
        //e.preventDefault();
        //e.stopPropagation();
        changePasswd();
        
    };
    const changeMyInfoPwdHandleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {           
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');                  
            setFormErrors(validate(formValues));    //Error state 값 갱신      
        }else{
            e.preventDefault();
            e.stopPropagation();
            if(formErrors.new_pw==='' && formErrors.new_pw2===''){
                changeMyInfoPwdSubmitForm(e);
            }            
        }
        
    };
    const validate = (values) => { //휴면해제요청 submit일때만 체크
        let errors = {};
        
        if (!values.new_pw) {
            errors.new_pw = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.new_pw) === false) {
            errors.new_pw = "패스워드 형식이 유효하지 않습니다.";
        } 
        checkValidNewPwd1();

        if (!values.new_pw2) {
            errors.new_pw2 = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.new_pw2) === false) {
            errors.new_pw2 = "패스워드 형식이 유효하지 않습니다.";
        }
        checkValidNewPwd2();
        return errors;
    };

    
    const checkValidNewPwd1 = () => {
        let el = document.getElementById('new_pw');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.new_pw = "필수 항목입니다";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.new_pw = "패스워드 형식이 유효하지 않습니다";         
        } else {
            el.classList.remove('is-invalid'); 
            formErrors.new_pw = "";     
        }
        feedBackErrorMsg('new_pw', formErrors.new_pw);
    }
    const checkValidNewPwd2 = () => {
        let el = document.getElementById('new_pw2');
        let el_new_pw1 = document.getElementById('new_pw');
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.new_pw2 = "필수 항목입니다";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.new_pw2 = "패스워드 형식이 유효하지 않습니다";         
        } else {
            if(el.value!==el_new_pw1.value){
                el.classList.add('is-invalid');
                formErrors.new_pw2 = "패스워드가 일치하지 않습니다";
            }else{
                el.classList.remove('is-invalid'); 
                formErrors.new_pw2 = "";
            }
        }
        feedBackErrorMsg('new_pw2', formErrors.new_pw2);
    }

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }

    // const yesLoginAfterRedirect = () => {
    //     if(localStorage.getItem('rurl')==='/modify'){
    //         window.location.href = "/modify";
    //     }else{
    //         if(localStorage.getItem('rurl')){
    //             window.location.href = localStorage.getItem('rurl');
    //         }else{
    //             FCS.yesLoginAfterRedirectHome();
    //         }
    //     }
    //     setTimeout(localStorage.removeItem('rurl'),1000); //로컬스토리지 rurl 삭제
    // }

    return (
        <>
        { isModify ? (
            <>
                <div className="row mt-3">
                    <div className="col-12">
                        <p className="mb-1 cfs-24 text-secondary display-3 fw-normal">비밀번호가 변경되었습니다.</p>
                        <p className="mb-1 cfs-15 text-secondary mt-3">원하는 서비스에 로그인하여 이용 할 수 있습니다.</p>
                    </div>
                </div>
             
                <button type="button" className="btn w-100 btn-primary bp-3 d-block mt-4 mx-auto cfs-18" onClick={FCS.yesLoginAfterRedirect}>확인</button>
            </>

        ):(

            <form className="g-3 needs-validation" noValidate onSubmit={changeMyInfoPwdHandleSubmit}>  
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <p className="mb-1 cfs-24 text-secondary">새 비밀번호를 입력해 주세요!</p>
                    </div>
                </div>
                <div className="row mb-1">
                    <InputGroup name="new_pw" title="새로운 비밀번호" type="password" placeholder="비밀번호 (8~16자리)" formValue={formValues.new_pw} formError={formErrors.new_pw} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                   
                </div>
                <div className="row mb-1">
                    <InputGroup name="new_pw2" title="새로운 비밀번호 확인" type="password" placeholder="비밀번호 확인" formValue={formValues.new_pw2} formError={formErrors.new_pw2} handleChange={handleChange} handleBlur={handleBlur} required={true} />
                    
                </div>
                <div className="row mb-1">
                    <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                </div>
                
                
                <div className="row mb-1">
                    <div className="col-sm-12 form-text">
                        <p className="mb-1">8-16자의 영문자+숫자+특수문자(예: @ # ...)를 모두 사용해야 합니다.</p>
                        <p className="mb-1">보안에 취약한 비밀번호를 사용하지 마세요.</p>
                    </div>
                </div>

                <div className="row px-5">
                    <div className="col-5 ps-0 pe-1"><a href="/findPwd"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-4 mx-auto cfs-18 text-white">취소</button></a></div>
                    <div className="col-7  pe-0 ps-1"><Button title="비밀번호 확인" /></div>
                </div>
            </form>
        )}

        { modalProgress && ( 
        <ModalPortal>
            <ProgressModal />
        </ModalPortal>
        )}
        </>
    );

}

export default NeedToChangePasswd;