import React, { useEffect, useState, useCallback, useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import ProgressModal from '../modal/ProgressModal';
import ModalPortal from '../../ModalPortal';
import axios from "axios";
import * as FCS from '../../common/js/common_spc';
import ChangeMyInfoNameForm from './modify/ChangeMyInfoNameForm';
import ChangeMyInfoBirthdayForm from './modify/ChangeMyInfoBirthdayForm';
import ChangeMyInfoEmailForm from './modify/ChangeMyInfoEmailForm';
import ChangeMyInfoPhoneForm from './modify/ChangeMyInfoPhoneForm';
import ChangeMyInfoPwdForm from './modify/ChangeMyInfoPwdForm';
import ModifyEmailAgree from '../dialog/ModifyEmailAgree';
import ModifyPrivacyHistory from '../dialog/ModifyPrivacyHistory';
import ModifyLoginLog from '../dialog/ModifyLoginLog';
import ModifyLeave from '../dialog/ModifyLeave';
import { ApiContext } from '../../Context';

function ModifyForm({isLogin}) {
    const context = useContext(ApiContext);
    const USER_API = context.url;
    const intialValues = { id: "", name: "", birthday: "", email: "", email_auth_date: "", phone: "", phone_auth_date: ""}; 
    const [formValues, setFormValues] = useState(intialValues);
    const [isOpenName, setIsOpenName] = useState(false);
    const [isOpenBirthday, setIsOpenBirthday] = useState(false);    
    const [isOpenPwd, setIsOpenPwd] = useState(false);
    const [isOpenEmail, setIsOpenEmail] = useState(false);
    const [isOpenPhone, setIsOpenPhone] = useState(false);    
    const [modalProgress, setModalProgress] = useState(false);    
   
    const doLogout = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'doLogout',
        },{ withCredentials: true });
        
        if(response.data.success===true){
            FCS.yesLoginAfterRedirectModify();
            setModalProgress(false);
        }else{
            setModalProgress(false);
        }
    }

    const getUserInfo = useCallback(async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'getUserInfoForMyPage',
        },{ withCredentials: true });
        
        if(response.data.success===true){
            setFormValues({ id: response.data.info.user_id, name: response.data.info.name, birthday: response.data.info.birthday, email: response.data.info.email, email_auth_date: response.data.info.email_auth_date, phone: response.data.info.phone, phone_auth_date: response.data.info.phone_auth_date });

            setModalProgress(false);
        }else{ 
            doLogout();
        }
    }, []);

    const changeNameFormOpen = () => {
        setIsOpenName(true);
        //setShowTooltips(showTooltips);
    }
    const changeNameFormClose = () => {
        setIsOpenName(false);
    }
    const changeNameFormCloseAfterGetData = () => {
        setIsOpenName(false);
        getUserInfo();
    }

    const changeBirthdayFormOpen = () => {
        setIsOpenBirthday(true);
        //setShowTooltips(showTooltips);
    }
    const changeBirthdayFormClose = () => {
        setIsOpenBirthday(false);
    }
    const changeBirthdayFormCloseAfterGetData = () => {
        setIsOpenBirthday(false);
        getUserInfo();
    }

    const changeEmailFormOpen = () => {
        setIsOpenEmail(true);
    }
    const changeEmailFormClose = () => {
        setIsOpenEmail(false);
    }
    const changeEmailFormCloseAfterGetData = () => {
        setIsOpenEmail(false);
        getUserInfo();
    }

    const changePhoneFormOpen = () => {
        setIsOpenPhone(true);
    }
    const changePhoneFormClose = () => {
        setIsOpenPhone(false);
    }
    const changePhoneFormCloseAfterGetData = () => {
        setIsOpenPhone(false);
        getUserInfo();
    }

    const changePwdFormOpen = () => {
        setIsOpenPwd(true);
    }
    const changePwdFormClose = () => {
        setIsOpenPwd(false);
    }
    const changePwdFormCloseAfterGetData = () => {
        setIsOpenPwd(false);
        getUserInfo();
    }

    const handlePrev = () => {
        if(localStorage.getItem('rurl')){
            window.location.href = localStorage.getItem('rurl');
        }else{
            window.location.href = "/modify";
        }
        setTimeout(localStorage.removeItem('rurl'),1000); //로컬스토리지 rurl 삭제
    }

    useEffect(() => {
        
        if(isLogin === true){
            getUserInfo(); 
            //setShowTooltips(!showTooltips);
        }
    },[isLogin, getUserInfo]);


    
    //useEffect(() => {
        //document.getElementById('getApp_reName').addEventListener('click', handleClick); //App.js에 foo요소 존재함
    //},[]);

    return (
        <>
            <div className="section container title-area"><h2 className="title-name h5 mb-3 cfs-24 text-center">회원정보 수정</h2></div>
            <div className="section container">

                {formValues.id ? (
                <>
                    <div className="row item px-1 pt-3 pb-2 mt-1">
                      
                        <label className="col-sm-3 col-form-label px-1 py-0 lh-base cfs-15 label-group-text">이름</label>
                        <div className="col-11 col-sm-7 pt-0 pb-0 px-1 lh-base cfs-18 font-weight-bold txt-value">{formValues.name}</div>
                        <div className="col-1 col-sm-2 pt-0 pb-0 px-1 position-relative" id="changeMyInfoName">
                            {isOpenName ? ( 
                            <img src="/img/ic_edit.png" onClick={changeNameFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit disabled" alt="ic-edit" />
                            ):(
                            <img src="/img/ic_edit.png" onClick={changeNameFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit " alt="ic-edit" />
                            )}
                        </div>
                    </div>
                    <div className="row px-1">                        
                        <div className="col-sm-3"></div>
                        <div id="changeMyInfoNameConfirm" className="col-sm-9 mx-0 px-1">
                            {isOpenName && 
                                <ChangeMyInfoNameForm changeNameFormClose={changeNameFormClose} changeNameFormCloseAfterGetData={changeNameFormCloseAfterGetData} />
                            }
                        </div>
                    </div>


                    <div className="row border-top item px-1 pt-3 pb-2 mt-1">
                        <label className="col-sm-3 col-form-label px-1 py-0 lh-base cfs-15 label-group-text">생년월일</label>
                        { !formValues.birthday ? (
                            <div id="changeMyInfoBirthday" className="col-sm-9 pt-0 pb-0 px-2 pe-0 lh-base">
                                <button type="button" className="btn btn-primary mb-1 bx-3 w-100 d-block cfs-15" onClick={changeBirthdayFormOpen}>생년월일을 입력해주세요!</button>
                            </div>
                        ):(
                            <>
                            <div className="col-11 col-sm-7 pt-0 pb-0 ps-1 pe-2 lh-base cfs-18 font-weight-bold txt-value">
                                {formValues.birthday}                                    
                            </div>
                            
                            <div className="col-1 col-sm-2 px-1 position-relative" id="changeMyInfoBirthday">
                                {isOpenBirthday ? (
                                <img src="/img/ic_edit.png" onClick={changeBirthdayFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit disabled" alt="ic-edit" />
                                ):(
                                <img src="/img/ic_edit.png" onClick={changeBirthdayFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit" alt="ic-edit" />
                                )}
                            </div>
                            </>
                        )}
                    </div>
                    <div className="row px-1">
                        <div className="col-sm-3"></div>
                        <div id="changeMyInfoBirthdayConfirm" className="col-sm-9 mx-0 px-1">
                            {isOpenBirthday && 
                                <ChangeMyInfoBirthdayForm changeBirthdayFormClose={changeBirthdayFormClose} changeBirthdayFormCloseAfterGetData={changeBirthdayFormCloseAfterGetData} birthday={formValues.birthday} />
                            }
                        </div>
                    </div>



                    <div className="row border-top item px-1 pt-3 pb-2 mt-1">
                        <label className="col-sm-3 col-form-label px-1 py-0 lh-base cfs-15 label-group-text">아이디</label>
                        <div className="col-sm-9 pt-0 pb-0 px-1 lh-base cfs-18 font-weight-bold txt-value">{formValues.id}</div>
                    </div>
                
                    <div className="row border-top item px-1 pt-3 pb-2 mt-1">
                        <label className="col-sm-3 col-form-label px-1 py-0 lh-base cfs-15 label-group-text">비밀번호</label>
                        <div className="col-11 col-sm-7 pt-0 pb-0 px-1 lh-base cfs-18">
                           <span className="cfs-15 text-info">비밀번호 변경은 수정버튼을 눌러주세요.</span>
                        </div>
                        <div className="col-1 col-sm-2 pt-0 pb-0 px-1 position-relative" id="changeMyInfoPwd">
                            {isOpenPwd ? (
                            <img src="/img/ic_edit.png" onClick={changePwdFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit disabled" alt="ic-edit" />
                            ):(
                            <img src="/img/ic_edit.png" onClick={changePwdFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit" alt="ic-edit" />
                            )}
                        </div>
                    </div>
                    <div className="row px-1">
                        <div className="col-sm-3"></div>
                        <div id="changeMyInfoPwdConfirm" className="col-sm-9 mx-0 px-1">
                            {isOpenPwd && 
                                <ChangeMyInfoPwdForm changePwdFormClose={changePwdFormClose} changePwdFormCloseAfterGetData={changePwdFormCloseAfterGetData} />
                            }
                        </div>
                    </div>
                
                    <div className="row border-top item px-1 pt-3 pb-2 mt-1">
                        <label className="col-sm-3 col-form-label px-1 py-0 lh-base cfs-15 label-group-text">이메일</label>
                        { !formValues.email ? (
                            <div id="changeMyInfoEmail" className="col-sm-9 pt-0 pb-0 px-2 pe-0 lh-base">
                                <button type="button" className="btn btn-primary mb-1 bx-3 w-100 d-block cfs-15" onClick={changeEmailFormOpen}>이메일 주소를 입력해주세요!</button>
                            </div>
                        ):(
                            <>
                                { !formValues.email_auth_date ? (
                                    <>
                                    <div className="col-8 col-sm-6 pt-0 pb-0 px-1 lh-base cfs-18 font-weight-bold txt-value">
                                        {formValues.email}
                                    </div>
                                    <div id="changeMyInfoEmail" className="col-4 col-sm-3 px-0 modify-auth-area">
                                        {isOpenEmail ? (
                                           
                                            <button type="button" className="btn btn-modify-auth mb-1 bx-3 d-block cfs-15 flex-nowrap" onClick={changeEmailFormOpen} data-container="body" data-toggle="popover" data-placement="bottom">인증이 필요해요</button>

                                        ):(
                                            <button type="button" className="btn btn-modify-auth mb-1 bx-3 d-block cfs-15 dropdown-toggle" onClick={changeEmailFormOpen} data-container="body" data-toggle="popover" data-placement="bottom">인증이 필요해요</button>
                                        )}
                                    </div>
                                    </>
                                ):(
                                    <>
                                    <div className="col-11 col-sm-7 pt-0 pb-0 px-1 lh-base cfs-18 font-weight-bold txt-value">
                                        {formValues.email}                                    
                                    </div>
                                    <div className="col-1 col-sm-2 px-1 position-relative" id="changeMyInfoEmail">
                                        {isOpenEmail ? (
                                        <img src="/img/ic_edit.png" onClick={changeEmailFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit disabled" alt="ic-edit" />
                                        ):(
                                        <img src="/img/ic_edit.png" onClick={changeEmailFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit" alt="ic-edit" />
                                        )}
                                    </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="row px-1">
                        <div className="col-sm-3"></div>
                        <div id="changeMyInfoEmailConfirm" className="col-sm-9 mx-0 px-1">
                            {isOpenEmail && 
                                <ChangeMyInfoEmailForm changeEmailFormClose={changeEmailFormClose} changeEmailFormCloseAfterGetData={changeEmailFormCloseAfterGetData} email_auth_date={formValues.email_auth_date} />
                            }
                        </div>
                    </div>

                    <div className="row border-top item px-1 pt-3 pb-2 mt-1">
                        <label className="col-sm-3 col-form-label px-1 py-0 lh-base cfs-15 label-group-text">휴대전화</label>
                        { !formValues.phone ? (
                            <> 
                                <div id="changeMyInfoPhone" className="col-sm-9 pt-0 pb-0 px-2 pe-0 lh-base">
                                    <button type="button" className="btn btn-primary mb-1 bx-3 w-100 d-block cfs-15" onClick={changePhoneFormOpen}>휴대폰번호를 입력해주세요!</button>
                                </div>
                            </>
                        ):(
                            <>
                                { !formValues.phone_auth_date ? (
                                    <>
                                    <div className="col-8 col-sm-6 pt-0 pb-0 px-1 lh-base cfs-18 font-weight-bold txt-value">
                                        {formValues.phone}
                                    
                                    </div>
                                    <div id="changeMyInfoPhone" className="col-4 col-sm-3 px-0 modify-auth-area">
                                        {/*<button type="button" className="btn btn-danger btn-modify-auth mb-1 bx-3 w-100 d-block cfs-15" onClick={changePhoneFormOpen} data-container="body" data-toggle="popover" data-placement="bottom">인증하기</button>*/}
                                        {isOpenPhone ? (
                                            <button type="button" className="btn btn-modify-auth mb-1 bx-3 d-block cfs-15 flex-nowrap" onClick={changePhoneFormOpen} data-container="body" data-toggle="popover" data-placement="bottom">인증이 필요해요</button>
                                        ):(
                                            <button type="button" className="btn btn-modify-auth mb-1 bx-3 d-block cfs-15 dropdown-toggle" onClick={changePhoneFormOpen} data-container="body" data-toggle="popover" data-placement="bottom">인증이 필요해요</button>
                                        )}
                                    </div>
                                   
                                    </>
                                ):(
                                    <>
                                    <div className="col-11 col-sm-7 pt-0 pb-0 ps-1 pe-2 lh-base cfs-18 font-weight-bold txt-value">
                                        {formValues.phone}                                    
                                    </div>
                                   
                                    <div className="col-1 col-sm-2 px-1 position-relative" id="changeMyInfoPhone">
                                        {isOpenPhone ? (
                                        <img src="/img/ic_edit.png" onClick={changePhoneFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit disabled" alt="ic-edit" />
                                        ):(
                                        <img src="/img/ic_edit.png" onClick={changePhoneFormOpen} role="button" width="20" height="20" className="position-absolute ic-edit" alt="ic-edit" />
                                        )}
                                    </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="row px-1 border-bottom">
                        <div className="col-sm-3"></div>
                        <div id="changeMyInfoPhoneConfirm" className="col-sm-9 mx-0 px-1">
                            {isOpenPhone && 
                                <ChangeMyInfoPhoneForm changePhoneFormClose={changePhoneFormClose} changePhoneFormCloseAfterGetData={changePhoneFormCloseAfterGetData} phone_auth_date={formValues.phone_auth_date} />
                            }
                        </div>
                    </div>
                </>

            ):(
                <>
                <div className="mb-2 row">
                    <label className="col-2 col-sm-3 py-1 px-1"><Skeleton height={40} /></label>
                    <div className="col-8 col-sm-7 py-1 px-1"><Skeleton height={40} /></div>
                    <div className="col-2 py-1 px-1"><Skeleton height={40} /></div>
                </div>
                <div className="mb-2 row">
                    <label className="col-2 col-sm-3 py-1 px-1"><Skeleton height={40} /></label>
                    <div className="col-8 col-sm-7 py-1 px-1"><Skeleton height={40} /></div>
                    <div className="col-2 py-1 px-1"><Skeleton height={40} /></div>
                </div>
                <div className="mb-2 row">
                    <label className="col-2 col-sm-3 py-1 px-1"><Skeleton height={40} /></label>
                    <div className="col-8 col-sm-7 py-1 px-1"><Skeleton height={40} /></div>
                    <div className="col-2 py-1 px-1"><Skeleton height={40} /></div>
                </div>
                <div className="mb-2 row">
                    <label className="col-2 col-sm-3 py-1 px-1"><Skeleton height={40} /></label>
                    <div className="col-8 col-sm-7 py-1 px-1"><Skeleton height={40} /></div>
                    <div className="col-2 py-1 px-1"><Skeleton height={40} /></div>
                </div>
                <div className="mb-2 row">
                    <label className="col-2 col-sm-3 py-1 px-1"><Skeleton height={40} /></label>
                    <div className="col-8 col-sm-7 py-1 px-1"><Skeleton height={40} /></div>
                    <div className="col-2 py-1 px-1"><Skeleton height={40} /></div>
                </div>
                </>
            )}
            </div>

            <div className="mb-5 py-5 section">
                {/*<ModifyAddress />*/}
                <div className="col-12 mb-2 d-flex bg-light px-3 py-1 rounded-1" onClick={() => FCS.openAddressbook()} role="button">
                    <div className="py-2 col-10"> 
                        <span className="d-block me-2 w-100 text-dialog-title">주소 <span className="text-info">(배송지관리)</span></span>
                    </div>
                    <div className="py-2 col-2 text-end"><img src="/img/mic_bl.png" alt="ic_arrow" className="ic_arrow" /></div>
                </div>
                
                <ModifyEmailAgree />
                <ModifyPrivacyHistory />
                <ModifyLoginLog />
                <ModifyLeave />

                { localStorage.getItem('rurl') &&
                <div className="mb-0 py-0 section">
                    <div className="ps-0 pe-1 d-flex justify-content-center"><p className="pt-3 text-center cfs-16 text-primary" onClick={handlePrev}><u role="button">이전페이지</u></p></div>
                </div>
                }

				<div className="col-12 py-3"><a href="https://cnts.godpeople.com/p/71896" target="_blank"><img src="https://cnts-image.godpeople.com/gallery/2023/bnr_isms600.png" className="w-100" /></a></div>
            </div>

            { modalProgress && ( 
            <ModalPortal>
                <ProgressModal />
            </ModalPortal>
            )}
        </>
    );

}
export default ModifyForm;