import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ApiContext } from '../Context';
import queryString from "query-string";
import ProgressModal from '../components/modal/ProgressModal';
import ModalPortal from '../ModalPortal';
import ModifyTemplate from '../components/auth/ModifyTemplate';
import AuthForm from '../components/auth/AuthForm';
import ModifyForm from '../components/auth/ModifyForm';
import ButtonCloseApp from '../components/common/ButtonCloseApp';
import axios from "axios";

function ModifyPage({location}) {
    const query = queryString.parse(location.search);
    
    const context = useContext(ApiContext);
    const USER_API = context.url;
    //쿼리스트링(rurl:이전페이지 이동[리다이렉트 주소]) 로컬스토리지 저장
    // if(query.rurl === undefined) localStorage.removeItem('rurl');
    // else localStorage.setItem('rurl',query.rurl); 
    if(query.rurl !== undefined) localStorage.setItem('rurl',query.rurl); 
    if(query.AppID !== undefined) localStorage.setItem('appid',query.AppID);
    
    const [modalProgress, setModalProgress] = useState(false);
    const [isCheckLogin, setIsCheckLogin] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const checkLogin = useCallback(async () => {
        
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'isLogin',
        },{ withCredentials: true });
        
        if(response.data.success===true){
            if(response.data.isLogin===true){
                setModalProgress(false);
                setIsLogin(true);
                setIsCheckLogin('checkOK');
            }else{
                setIsLogin(false);
                setIsCheckLogin('checkOK');  
            }
        }else{
            alert(response.data.error_msg);
        }
    },[]);

    useEffect(() => {
        checkLogin(); 
        return () => {
            setModalProgress(false);            
        };
    },[checkLogin]);

    const useTitle = () => {
        const [title, setTitle] = useState(null);
        const updateTitle = () => {
          const htmlTitle = document.querySelector("title");
          htmlTitle.innerHTML = title;
        };
        useEffect(updateTitle, [title]);
        return setTitle;
    };
    const changeTitle = useTitle();
    useEffect(() => {
        changeTitle("갓피플 회원정보수정");
    },[changeTitle]);

    return (
        <>
        <ModifyTemplate>            
            { localStorage.getItem('appid') && 
                <ButtonCloseApp />
            }

            { isCheckLogin === 'checkOK' && (
                <>              
                    { isLogin ? ( 
                       <ModifyForm isLogin={isLogin} /> 
                    ):( 
                       <AuthForm />
                    )}
                </>
            )}
                
            { modalProgress && ( 
                <ModalPortal>
                    <ProgressModal />
                </ModalPortal>
            )}
        </ModifyTemplate>
        </>
    );
}
export default ModifyPage;
