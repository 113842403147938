import React from 'react';
import * as FCS from '../../common/js/common_spc';

function ButtonCloseApp() {
    const wClose = () => {
        FCS.send_post('Close');
    };

    return (
      <>
          <button className="btn-app-close btn" onClick={wClose}><img src={process.env.PUBLIC_URL + '/img/btn_t_close.png'} alt="ic-back" /></button>
      </>
    );
  }
  
export default ButtonCloseApp;
  
