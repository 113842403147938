import React from 'react';
//type : main|find
function Heaer({type}) 
{
    return (
        <>
            {type==="modify" ? (
                <div
                    id="gpHead"
                    className="d-flex flex-column align-items-center"
                    role="banner">
                    <div className="wrap_head col-12 col-lg-9">
                        <nav id="gpGnb">
                            <div className="logo-area my-0 pt-3">
                                { localStorage.getItem('appid') ? (
                                    <img src="https://image.godpeople.com/common/ci/ci_gp_350_nc.png" className="ic_logo" alt="logo" width="140" height="22" />
                                ):(
                                    <a href="https://www.godpeople.com" className="link_logo"><img src="https://image.godpeople.com/common/ci/ci_gp_350_nc.png" className="ic_logo" alt="logo" width="140" height="22" /></a>
                                )}
                                <span className="title">회원정보수정</span>
                            </div>
                        </nav>
                    </div>
                </div>
            ):(
                <div
                id="gpHead"
                className="d-flex flex-column align-items-center"
                role="banner">

                {type==="find" ? (
                    <h1 className="h5 mt-0 mb-0 cfs-24 logo-area">
                        { localStorage.getItem('appid') ? (
                            <img src="https://image.godpeople.com/common/ci/ci_gp_350_nc.png" className="ic_logo" alt="logo" />
                        ):(
                            <a href="https://www.godpeople.com" className="link_logo"><img src="https://image.godpeople.com/common/ci/ci_gp_350_nc.png" className="ic_logo" alt="logo" /></a>
                        )}
                        
                    </h1>
                ):(
                    <h1 className="h5 my-3 cfs-24 logo-area">
                        { localStorage.getItem('appid') ? (
                            <img src="https://image.godpeople.com/common/ci/ci_gp_350_nc.png" className="ic_logo" alt="logo" />
                        ):(
                            <a href="https://www.godpeople.com" className="link_logo"><img src="https://image.godpeople.com/common/ci/ci_gp_350_nc.png" className="ic_logo" alt="logo" /></a>
                        )}
                        
                    </h1> 
                )}
                </div>
            )}
        </>
    );
}
export default Heaer;