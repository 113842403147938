import React, { useEffect, useRef, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Snackbar } from '@material-ui/core';

import { useHistory } from "react-router-dom";
import axios from 'axios';
import '../../scss/_donation.scss';
import { regex_Mail, regex_Phone } from '../../common/js/common_spc';
import { ApiContext } from '../../Context';

const DonationApply = () => {

    const [ active, setActive ]  = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ checkInfo, setCheckInfo ] = useState(false);
    const [ checkChurch, setCheckChurch ] = useState(false);
    const [ userInfo, setUserInfo ] = useState({
        'name': '',
        'email': '',
        'phone': ''
    })
    const [ imageUrl, setImageUrl ] = useState("");
    const [ btnActive, setBtnActive ] = useState(false);
    const [ churchName, setChurchName ] = useState("");
    const [ openLogin, setOpenLogin ] = useState(false);
    const flowingUpload = useRef(null);
    const churchRef = useRef(null);

    // ROUTER
    const history = useHistory()

    // API
    const CRMAPI = "https://crmapi.godpeople.com";
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const getUserBasicInfo = async () => {
        const USER_DONATION_API = USER_API+"/donation/";
        try {
            await axios.post(
                USER_DONATION_API,
                {
                    "task": "getSpecialMemberInfo"
                },
                { withCredentials: true}
            )
            .then((res)=> res.data.success === true ? setUserInfo(res.data.result) : failAlert(res.data.error_code))
        } catch(err) {
            console.error(err);
        }
    }

    const uploadHandle = () => {
        flowingUpload.current.click()
    }

    const uploadFileHandle = async (e) => {
		try {
			setLoading(true);
			const formData = new FormData();
			const file = e.target.files[0];
			formData.append("file", file);
			formData.append("task", "createImageUpload");
			formData.append("ori", true);
			formData.append("dir", "/mall");
			formData.append("name", "donation_");
			
			await axios.post(CRMAPI, formData, { withCredentials: true })
			.then((res)=>res.data.success === true && imageDataHandle(res.data.result))
		} catch(e) {
			console.error(e.message);
		}
	}

    const imageDataHandle = (r) => {
		setImageUrl(r);
		setLoading(false);
	}

    const failAlert = (code) => {
        if( code == '7801' ) {
            alert('이미 등록하셨습니다.');
            window.location.href="https://cnts.godpeople.com/p/76336"            
        } else {
            setOpenLogin(true)
        }
        // alert('정보를 찾을 수 없습니다.');
    }

    useEffect(() => {
        getUserBasicInfo()
    },[])

    // 모달
    const [ open, setOpen ] = useState(false);
    const [ modifyData, setModifyData ] = useState({
        kind: '',
        value: ''
    })
    const [ newAuthValue, setNewAuthValue] = useState("");
    const [ insertAuth, setInsertAuth ] = useState(false);
    const [ insertAuthValue, setInsertAuthValue] = useState("");
    const editHandle = (kind, value) => {
        setModifyData({
            kind: kind,
            value: value
        })
        setOpen(true)
        setInsertAuth(false);
        setInsertAuthValue("");
    }

    const handleClose = () => {
        setOpen(false)
        setInsertAuth(false);
        setInsertAuthValue("");
        setNewAuthValue("");
    }

    //인증번호관련함수
    const sendSMS = async () => {
        if( !newAuthValue ) {
            alert('수정하실 정보를 입력해주세요.')
            return;
        }
        
        if( modifyData.value !== newAuthValue ) {
            if( regex_Phone(newAuthValue) === false )
            {
                alert(`휴대폰 번호 형식이 유효하지 않습니다.`);
            } else {
                await axios.post(USER_API,{
                    task: 'sendSMS_AuthNo',
                    phone: newAuthValue,
                    akind: 'Modify'
                }).then((res) => res.data.success ? insertAuthHandle() : alert(res.data.error_msg) )
            }
        } else {
            alert(`이전 ${modifyData.kind === 'phone' ? '휴대폰정보와' : '이메일정보와'} 동일합니다.`);
        }
    }

    const insertAuthHandle = () => {
        setInsertAuth(true);
    }
    
    // 인증OK
    const modifyPhone = async () => {
        await axios.post(USER_API,{
            task: 'confirmSMS_AuthNo',
            phone: newAuthValue,
            auth_no: insertAuthValue,
            akind: 'Modify'
        }).then((res) => res.data.success ? modifyDoneHandle() : alert(res.data.error_msg) )
    }

    const modifyEmail = async () => {
        await axios.post(USER_API,{
            task: 'confirmMail_AuthNo',
            phone: newAuthValue,
            auth_no: insertAuthValue,
            akind: 'Modify'
        }).then((res) => res.data.success ? modifyDoneHandle() : alert(res.data.error_msg) )
    }

    // 이메일수정
    const sendMail = async() => {
        if( !newAuthValue ) {
            alert('수정하실 정보를 입력해주세요.')
            return;
        }
        
        if( modifyData.value !== newAuthValue ) {
            
            if( regex_Mail(newAuthValue) === false )
            {
                alert(`이메일 형식이 유효하지 않습니다.`);
            } else {
                await axios.post(USER_API,{
                    task: 'sendMail_AuthNo',
                    email: newAuthValue,
                    akind: 'Modify'
                }).then((res) => res.data.success ? insertAuthHandle() : alert(res.data.error_msg) )
            }
        } else {
            alert(`이전 ${modifyData.kind === 'phone' ? '휴대폰정보와' : '이메일정보와'} 동일합니다.`);
        }
    }

    const modifyDoneHandle = () => {
        alert('수정되었습니다.');
        getUserBasicInfo()
        setOpen(false)
        setInsertAuth(false);
        setInsertAuthValue("");
        setNewAuthValue("");
    }

    // HANDLE
    const sendHandle = (v) => {
        if( v === 'phone') {
            sendSMS()
        } else {
            sendMail()
        }
    }
    const modifyHandle = (v) => {
        if( v === 'phone') {
            modifyPhone()
        } else {
            modifyEmail()
        }
    }

    // INTEREST
    const [ interest, setInterest ] = useState({
        bible: false,
        book: false,
        food: false,
        goods: false,
        kids: false,
        all: false
    })
    const interestHandle = (v) => {
        if( v !== 'all' ) {
            setInterest((prev) => ({
                ...prev,
                [v]: !prev[v]
            }))
        } else {
            if( !interest.all ) {
                setInterest({
                    all: true,
                    bible: true,
                    book: true,
                    food: true,
                    goods: true,
                    kids: true
                })
            } else {
                setInterest({
                    bible: false,
                    book: false,
                    food: false,
                    goods: false,
                    kids: false,
                    all: false
                })
            }
            
        }
    }

    // 신청버튼 체크
    useEffect(() => {
        
        if( churchName == "" ) {
            setBtnActive(false)
            return;
        }
        if( !checkChurch ) {
            setBtnActive(false)
            return;
        }
        if( !checkInfo ) {
            setBtnActive(false)
            return;
        }
        if( !imageUrl ) {
            setBtnActive(false)
            return;
        }
        const checkInterest = Object.values(interest).some(value => value === true);
        if( !checkInterest ) {
            setBtnActive(false)
            return;
        }
        
        setBtnActive(true)
    }, [churchName, checkChurch, checkInfo, interest, imageUrl])
    

    // 최종등록
    const [ alertOpen, setAlertOpen] = useState(false);
    const [ message, setMessage ] = useState("");

    const checkChurchRef = useRef(null)
    const checkChurchTextRef = useRef(null)
    const checkInfoRef = useRef(null)
    const checkInfoTextRef = useRef(null)
    const imageUrlRef = useRef(null)
    const interestRef = useRef(null)
    const applySpecialMember = async () => {

        if( churchName == "" ) {
            setMessage('교회명을 입력해주세요.');
            setAlertOpen(true)
            setTimeout(() => setAlertOpen(false), 2000)
            churchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            churchRef.current.focus();
            checkInfoRef.current.style.setProperty('color', '#FF5B8A')
            setTimeout(() => checkInfoRef.current.style.setProperty('color', '#FFF'), 2000)
            return;
        }

        if( !checkChurch ) {
            setMessage('교단정보 확인에 동의해주세요.');
            setAlertOpen(true)
            setTimeout(() => setAlertOpen(false), 2000)
            checkChurchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            checkChurchRef.current.focus();
            checkChurchTextRef.current.style.setProperty('color', '#FF5B8A')
            setTimeout(() => checkChurchTextRef.current.style.setProperty('color', '#FFF'), 2000)
            return;
        }

        if( !checkInfo ) {
            setMessage('개인정보 수집에 동의해주세요.');
            setAlertOpen(true)
            setTimeout(() => setAlertOpen(false), 2000)
            checkInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            checkInfoRef.current.focus();
            checkInfoTextRef.current.style.setProperty('color', '#FF5B8A')
            setTimeout(() => checkInfoTextRef.current.style.setProperty('color', '#FFF'), 2000)
            return;
        }

        if( !imageUrl ) {
            setMessage('파일을 첨부해주세요.');
            setAlertOpen(true)
            setTimeout(() => setAlertOpen(false), 2000)
            imageUrlRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            imageUrlRef.current.focus();
            imageUrlRef.current.style.setProperty('border-color', '#FF5B8A')
            setTimeout(() => imageUrlRef.current.style.setProperty('border-color', '#000'), 2000)
            return;
        }
        const checkInterest = Object.values(interest).some(value => value === true);
        if( !checkInterest ) {
            setMessage('관심있는 플로잉을 체크해주세요.');
            setAlertOpen(true)
            setTimeout(() => setAlertOpen(false), 2000)
            interestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            interestRef.current.focus();
            interestRef.current.style.setProperty('color', '#FF5B8A')
            setTimeout(() => interestRef.current.style.setProperty('color', '#666'), 2000)
            return;
        }
        try {
            // $cate = $P['category'];
		    // $kind = $P['kind'];
		    // $church_name = $P['churchName'];
		    // $upload_file = $P['uploadFile'];
		    // $agree = $P['agree'];
        
            let interestList = Object.keys(interest).filter((key) => interest[key] === true).join(',');
            if( interestList.includes('all') )
            {
                interestList = 'all';
            }

            // const USER_DONATION_API = USER_API+"/donation";
            const USER_DONATION_API = USER_API+"/donation/";
            await axios.post(
                USER_DONATION_API,
                {
                    "task": "createSpecialMemberJson",
                    "category": "flowing",
                    "kind": interestList,
                    "churchName": churchName,
                    "uploadFile": imageUrl,
                    "agree": checkChurch && checkInfo ? "Y" : "N"
                },
                { withCredentials: true}
            )
            .then((res)=> res.data.success === true ? history.push('/donation/result') : alert('등록에 실패했습니다. 관리자에게 문의해주세요.'))

        } catch(err) {
            console.error(err);
        }
    }

    return (
        <main className="flowing-apply-container d-flex flex-column w-100 justify-content-center align-items-center p-0">
            <div className="flowing-apply-content">
                <div className="flowing-title d-flex justify-content-center w-100 flex-column">
                    <div className="flowing-title-top">
                        <img src="https://spimage.godpeople.com/gpImage/common/flowing_title.png" className="border-white" />
                        <div className="py-4 text-white text-center text-nowrap">
                            갓피플 플로잉 사역 소식을 받고 싶으신 <br />
                            사역자분들을 모집합니다. 신청하시면, <br />
                            갓피플 쇼핑을 통해 제공되는 플로잉 상품의 소식을 <br />
                            순차적으로 또는 일괄적으로 받아보실 수 있으며, <br />
                            이를 통해 플로잉 신청도 가능합니다.<br />
                        </div>
                    </div>
                    <div className="flowing-title-bottom">
                        <div className="w-100 d-flex justify-content-center">
                            <div className="flowing-title-middle-title">신청대상</div>
                        </div>
                        <div className="text-white py-3 flowing-title-middle-content text-center">
                            개척교회 사역자 / 선교사 / 선교단체 간사 / 험지 목회자
                        </div>
                    </div>
                    
                    <div className="flowing-title-bottom-img">
                        <img src="https://spimage.godpeople.com/gpImage/common/flowing_img.png" />
                    </div>
                </div>
                <div className="flowing-middle">
                    <div className="flowing-middle-info">
                        <div className="input-info">
                            <div>이름</div>
                            <input type="text" onClick={()=>alert('이름은 수정이 불가합니다.')} value={userInfo.name} disabled={true}/>
                        </div>
                        <div className={`${active === 'phone' ? 'active': ''} input-info`}>
                            <div>휴대폰</div>
                            <input type="text" onFocus={()=>setActive('phone')} value={userInfo.phone}/>
                            <button className="edit-btn" onClick={()=>editHandle('phone', userInfo.phone)}>
                                {
                                    active === 'phone' ?
                                    <img src="https://spimage.godpeople.com/gpImage/common/flowing_edit.png" style={{ width: '30px' }} />
                                    : <img src="https://spimage.godpeople.com/gpImage/common/flowing_edit_g.png" style={{ width: '30px' }} />
                                }
                            </button>
                        </div>
                        <div className={`${active === 'email' ? 'active': ''} input-info`}>
                            <div>이메일</div>
                            <input type="text" onFocus={()=>setActive('email')} value={userInfo.email}/>
                            <button className="edit-btn" onClick={()=>editHandle('email', userInfo.email)}>
                                {
                                    active === 'email' ?
                                    <img src="https://spimage.godpeople.com/gpImage/common/flowing_edit.png" style={{ width: '30px' }} />
                                    : <img src="https://spimage.godpeople.com/gpImage/common/flowing_edit_g.png" style={{ width: '30px' }} />
                                }
                            </button>
                        </div>
                        <div className={`${active === 'church' ? 'active': ''} input-info`}>
                            <div>교회명</div>
                            <input type="text" onFocus={()=>setActive('church')} ref={churchRef} value={churchName} onChange={(e)=>setChurchName(e.target.value)}/>
                            <button className="edit-btn" onClick={()=>churchRef.current.focus()}>
                                {
                                    active === 'church' ?
                                    <img src="https://spimage.godpeople.com/gpImage/common/flowing_edit.png" style={{ width: '30px' }} />
                                    : <img src="https://spimage.godpeople.com/gpImage/common/flowing_edit_g.png" style={{ width: '30px' }} />
                                }
                            </button>
                        </div>
                        {
                            imageUrl ?
                            <>
                                <div className={`input-info`}>
                                    <div className="d-flex align-items-center" style={{ gap: '4px' }}>
                                        첨부파일
                                        <img src="https://spimage.godpeople.com/gpImage/common/flowing_file.png" style={{ width: '22px', zIndex: '1' }} />
                                        {/* <FontAwesomeIcon icon={faPaperclip} style={{ fontSize:"20px", color: "#666" }}/> */}
                                    </div>
                                    <input type="text" value={imageUrl.split('/').pop()} readOnly style={{ paddingLeft: '28px'}} />
                                    <button className="edit-btn" onClick={uploadHandle}>
                                        <img src="https://spimage.godpeople.com/gpImage/common/flowing_edit_g.png" style={{ width: '30px' }} />                                    
                                    </button>
                                </div>
                                <div className="text-center" style={{ color: "#FF5B8A", fontSize:".9em", marginTop: "-5px", fontWeight: "500" }}>*섬기는 교회의 주보이미지</div>
                            </>
                            : null
                        }
                    </div> 
                    {
                        imageUrl ? null
                        :
                        <div className="flowing-middle-upload">
                            <button onClick={uploadHandle} className="cursor-pointer" ref={imageUrlRef}>
                                <label HtmlFor="" className="m-0 d-flex align-items-center gap-2 cursor-pointer">
                                    <span>교회확인 파일첨부(필수)</span>
                                    <img src="https://spimage.godpeople.com/gpImage/common/flowing_file_w.png" style={{ width: '22px', zIndex: '1' }} />
                                    {/* <FontAwesomeIcon icon={faPaperclip} style={{ fontSize:"20px" }}/> */}
                                </label>
                                <input type="file" onFocus={()=>setActive('church')} ref={flowingUpload} onChange={(e)=>uploadFileHandle(e)}/>
                            </button>
                            <span>*섬기는 교회의 주보이미지</span>
                        </div>
                    }
                    <div className="flowing-middle-interest" style={{ borderTop: "1px solid #666", marginTop: "40px" }}>
                        <div className="d-flex justify-content-center flex-column align-items-center" style={{ padding: "30px 0" }}>
                            <div className="text-center" style={{ color: "#666" }} ref={interestRef}>관심있는 플로잉 상품에 중복체크해주세요 (필수)</div>
                            <ul className="interest-grid">
                                <li className={`cursor-pointer ${interest.all ? 'active' : ''}`} onClick={()=>interestHandle('all')}>
                                    <button className={`flowing-interest-btn cursor-pointer`}>
                                        <div className={`return-checked ${interest.all ? 'checked' : 'unchecked'}`} />
                                    </button>
                                    <div className="text-nowrap">전체</div>
                                </li>
                                <li className={`cursor-pointer ${interest.bible ? 'active' : ''}`} onClick={()=>interestHandle('bible')}>
                                    <button className={`flowing-interest-btn cursor-pointer`}>
                                        <div className={`return-checked ${interest.bible ? 'checked' : 'unchecked'}`} />
                                    </button>
                                    <div className="text-nowrap">성경</div>
                                </li>
                                <li className={`cursor-pointer ${interest.book ? 'active' : ''}`} onClick={()=>interestHandle('book')}>
                                    <button className={`flowing-interest-btn cursor-pointer`}>
                                        <div className={`return-checked ${interest.book ? 'checked' : 'unchecked'}`} />
                                    </button>
                                    <div className="text-nowrap">도서</div>
                                </li>
                                <li className={`cursor-pointer ${interest.food ? 'active' : ''}`} onClick={()=>interestHandle('food')}>
                                    <button className={`flowing-interest-btn cursor-pointer`}>
                                        <div className={`return-checked ${interest.food ? 'checked' : 'unchecked'}`} />
                                    </button>
                                    <div className="text-nowrap">식품</div>
                                </li>
                                <li className={`cursor-pointer ${interest.goods ? 'active' : ''}`} onClick={()=>interestHandle('goods')}>
                                    <button className={`flowing-interest-btn cursor-pointer`}>
                                        <div className={`return-checked ${interest.goods? 'checked' : 'unchecked'}`} />
                                    </button>
                                    <div className="text-nowrap">용품</div>
                                </li>
                                <li className={`cursor-pointer ${interest.kids ? 'active' : ''}`} onClick={()=>interestHandle('kids')}>
                                    <button className={`flowing-interest-btn cursor-pointer`}>
                                        <div className={`return-checked ${interest.kids ? 'checked' : 'unchecked'}`} />
                                    </button>
                                    <div className="text-nowrap">어린이</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flowing-bottom">
                    <div className="d-flex align-items-center gap-2">
                        <button className={`flowing-check-btn ${checkInfo ? 'active' : ''} cursor-pointer`} onClick={()=>setCheckInfo(!checkInfo)} ref={checkInfoRef}>
                            <div className={`return-checked ${checkInfo ? 'checked' : 'unchecked'}`} />
                        </button>
                        <div style={{color:'#FFF'}} ref={checkInfoTextRef}>개인정보 수집 및 이용에 동의합니다.</div>
                    </div>
                    <div className="pt-3 pb-5 px-1" style={{ color: "#cfe5ff", fontSize: "14px", lineHeight: "150%" }}>
                        수집 목적 : 플로잉 상품 및 블레싱 책 발송 등의 안내를 위해 수집.<br />
                        수집 항목 : 이름, 휴대폰번호, 이메일, 교회명<br />
                        이용 기간 : 해지하기 전까지<br />
                        <div style={{ lineHeight: "135%" }}>
                            동의 거부권 등에 대한 고지 : 개인정보 수집을 거부하실 수 있으나, 거부 시 플로잉 소식받기신청 불가.
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <button className={`flowing-check-btn ${checkChurch ? 'active' : ''} cursor-pointer`} onClick={()=>setCheckChurch(!checkChurch)} ref={checkChurchRef}>
                            <div className={`return-checked ${checkChurch ? 'checked' : 'unchecked'}`} />
                        </button>
                        <div className="line-height-125" style={{color:'#FFF'}} ref={checkChurchTextRef}>
                            복음주의 교단에서 이단으로 판정된 단체일 경우 지원받을 수 없음을 동의합니다.
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <button className={`apply-button ${ btnActive ? 'active cursor-pointer' : ''}`} onClick={applySpecialMember}>
                            신청하기
                        </button>
                    </div>
                </div>
            </div>
            {/* SNACKBAR */}
            <Snackbar 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertOpen}
                onClose={()=>setAlertOpen(false)}
            >
                <div className="apply-alert">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    {message}
                </div>
            </Snackbar>

            {/* MODAL */}
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className="edit-dialog" style={{ padding: "40px" }}>
                    <div className="d-flex justify-content-between">
                        <h5 className="m-0">{modifyData.kind === 'phone' ? '휴대폰' : '이메일'} 정보수정</h5>
                        <button className="cursor-pointer m-0 p-0" style={{ border: 'none', background: 'transparent'}} onClick={handleClose}>
                            <img src="https://spimage.godpeople.com/gpImage/common/flowing_x.png" style={{ width: '22px', zIndex: '1' }} />
                        </button>
                    </div>
                    <div className="pt-4 mt-3 border-top">
                        <div className="active input-info px-4">
                            <div className="text-nowrap">{modifyData.kind === 'phone' ? '휴대폰' : '이메일'}</div>
                            <input type="text" value={newAuthValue} style={{ minWidth: "300px" }} onChange={(e)=>setNewAuthValue(e.target.value)}/>
                        </div>
                        {
                            insertAuth ?
                                <>
                                    <div className="input-info px-3 mt-3">
                                        <div className="text-nowrap">인증번호</div>
                                        <input type="text" value={insertAuthValue} style={{ minWidth: "300px" }} onChange={(e)=>setInsertAuthValue(e.target.value)}/>
                                    </div>
                                    <div className="mt-4 w-100 d-flex justify-content-center">
                                        <button className="btn w-50 text-white" style={{ fontSize: "18px", borderRadius: "32px", background: "#FF5B8A" }} onClick={() => modifyHandle(modifyData.kind)}>
                                            수정완료
                                        </button>
                                    </div>
                                </>
                            : <div className="mt-4 w-100 d-flex justify-content-center">
                                <button className="btn btn-primary w-50" style={{ fontSize: "18px", borderRadius: "32px" }} onClick={() => sendHandle(modifyData.kind)}>
                                    인증번호받기
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </Dialog>

            {/* FAIL MODAL */}
            <Dialog
                open={openLogin}
            >
                <div className="edit-dialog" style={{ padding: "40px", minWidth: "340px" }}>
                    <div className="text-center mb-2" style={{ fontWeight: "500" }}>로그인 후 이용가능합니다.</div>
                    <div className="text-center" style={{ color: "#999", fontSize: "15px" }}>로그인 페이지로 이동하시겠습니까?</div>
                    <div className="d-flex w-100 justify-content-between gap-2 mt-4">
                        <a className="btn w-50 py-2 text-white" style={{ fontSize: "16px", borderRadius: "8px", background: "#666" }} href="https://cnts.godpeople.com/p/76336">
                            취소
                        </a>
                        <a className="btn btn-primary w-50 py-2" style={{ fontSize: "16px", borderRadius: "8px" }} href={`https://member.godpeople.com/m/login.html?url=${window.location.href}`}>
                            로그인
                        </a>
                    </div>
                </div>

            </Dialog>
        </main>
    );
}
 
export default DonationApply;