import React, { useState, useCallback, useContext } from 'react';
import InputGroup from '../../common/InputGroup';

import ProgressModal from '../../modal/ProgressModal';
import ModalPortal from '../../../ModalPortal';
import axios from "axios";
import * as FCS from '../../../common/js/common_spc';
import { ApiContext } from '../../../Context';
import countryCodes from '../../json/countryCodes.json';

function ChangeMyInfoPhoneForm({changePhoneFormClose, changePhoneFormCloseAfterGetData, phone_auth_date}) {
    const intialValues = { phone:"", user_n: "", authNo:"", fail_msg_phone: ""}; 
    const [show, setShow] = useState(false);
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유 
    const [modalProgress, setModalProgress] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);
    
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const closeForm = () => {
        //onReset();
        changePhoneFormClose();
    }
    const closeFormAfterData = () => {
        //onReset();
        changePhoneFormCloseAfterGetData();
    }
    

    const changePhone = async(auth_idx) => {
        
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'changePhone',
            new_phone: formValues.phone,
            auth_idx: auth_idx
           
        },{ withCredentials: true });
        
        if(response.data.success===true){
            //성공하면.. 리얼 아이디 보이도록
            setFormValues({ ...formValues, info: response.data.info });
            setModalProgress(false);
            closeFormAfterData();
        }else{
            //실패시
             
            document.getElementById('fail-msg-phone').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('fail_msg_phone', formErrors.failMsg); 
            setModalProgress(false);
        }
    }


    const handleBlur = (e) => {
        
        const { name } = e.target;
       
        if (name === 'phone'){
            checkValidPhone();
        }
        if (name === 'authNo'){
            checkValidAuthNo();
        }
       
    };

    const handleChange = useCallback(e => {
        //alert('handlechange')
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);

    const changeMyInfoPhoneSubmitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        confirmAuthNoClick();
    };
    const changeMyInfoPhoneHandleSubmit = (e) => {
       
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
                form.classList.add('was-validated');
                checkValidPhone();
            
            }else{
                e.preventDefault();
                e.stopPropagation();
                
                if(!isValidPhone){
                    
                    checkValidPhone();
                   
                }else{
                   // console.log('show:'+show)
                    if(!show){
                        document.getElementById('phone').classList.add('is-invalid'); 
                        formErrors.phone = "인증번호 발송을 눌러주세요.";
                        feedBackErrorMsg('phone', formErrors.phone);
                    }else{
                        
                        changeMyInfoPhoneSubmitForm(e);
                    }
                }
            }
        
    };
    const checkValidPhone = () => {
        let el = document.getElementById('phone');
        
        if (!el.value) {
            el.classList.add('is-invalid');
            formErrors.phone = "필수 항목입니다";
            setIsValidPhone(false);
         
        } else if (FCS.regex_Phone(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.phone = "휴대폰 번호 형식이 유효하지 않습니다.";
            setIsValidPhone(false);
        } else {
            el.classList.remove('is-invalid');
            el.classList.add('is-valid'); 
            formErrors.phone = "";
            setIsValidPhone(true);
        }
        feedBackErrorMsg('phone', formErrors.phone);
    }
   
    const checkValidAuthNo = () => {
        let el = document.getElementById('authNo');
        if (!el.value) {
            el.classList.add('is-invalid');
            formErrors.authNo = "필수 항목입니다";
        } else {
            el.classList.add('is-valid'); 
            formErrors.authNo = "";
        }
        feedBackErrorMsg('authNo', formErrors.authNo);
    }

    //인증번호관련함수
    const sendSMS = async() => {
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
                task: 'sendSMS_AuthNo',
                phone: formValues.phone,
                akind: 'Modify'
        });
        
        if(response.data.success===true){
            document.getElementById('authNo').classList.remove('is-invalid');
            formErrors.authNo = "문자 확인 후 인증번호를 입력해주세요";
            feedBackErrorMsg('authNo', formErrors.authNo);
            //  setIsValidAuthNumber(true);
        }else{
            document.getElementById('authNo').classList.add('is-invalid');
            formErrors.authNo = response.data.error_msg;
            feedBackErrorMsg('authNo', formErrors.authNo);
        }
    }
    const sendSMSClick = () => {
        setFormValues({ ...formValues, authNo: "" });
        sendSMS();
    };  
    const onSMSBtnClick = () => {
        if(document.getElementById('phone').value.length===0){
            document.getElementById('phoneFrm').classList.add('was-validated');
            checkValidPhone();
        }else{
            if(isValidPhone){
                setShow(!show);
                //인증메일발송 영역 활성화
                document.getElementById('phone').classList.remove('is-invalid');
                formErrors.phone = "";
                feedBackErrorMsg('phone', formErrors.phone);
                //인증메일발송버튼 안보이도록
                document.getElementById('phoneAuthBtn').disabled  = true;
                //인증메일발송
                sendSMS();
            }
        }
    };
    const confirmAuthNoClick = async() => {
        if(document.getElementById('authNo').value.length===0){
            document.getElementById('authNo').classList.add('is-invalid');
            formErrors.phone = "인증번호를 입력해주세요.";
            feedBackErrorMsg('authNo', formErrors.phone);
            
        }else{
            setModalProgress(true);
            let el = document.getElementById('authNo');
        // alert(formValues.authNumber)
            //let USER_API = "https://userapi.godpeople.com/";
            const response = await axios.post(USER_API,{
                    task: 'confirmSMS_AuthNo',
                    phone: formValues.phone,
                    auth_no: formValues.authNo,
                    akind: 'Modify'
            });
            
            if(response.data.success===true){
                el.classList.remove('is-invalid');  
                el.classList.add('is-valid');     
                document.getElementById('phone').classList.add('is-valid'); 
                formErrors.authNo = "";
           
                changePhone(response.data.auth_idx);
            }else{
                el.classList.add('is-invalid');       
                formErrors.authNo = "인증번호를 다시 확인해주세요";
                feedBackErrorMsg('authNo', formErrors.authNo);
             //   setIsValidAuthNo(false);
                setModalProgress(false);
            }
        }
    };

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }
    


    return (
        <>
        <form id="phoneFrm" className="g-3 needs-validation" noValidate onSubmit={changeMyInfoPhoneHandleSubmit}>
        <div className="mb-3">
          
            <div className="row mb-1">  
                {phone_auth_date ? (
                    <>
                    <div>
                        <label className="col-form-label label_text">휴대폰 번호 수정</label>
                    </div>
                
                    <div className="d-flex">
                        <div className="col-9 pe-1">
                            <input
                            type="tel"
                            className="form-control pt-2 pb-2 pe-0"
                            id="phone"
                            name="phone"
                            placeholder="변경할 휴대폰 번호" 
                            value={formValues.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required />
                        
                            <div className="invalid-feedback">{formErrors.phone}</div>
                        
                        </div>
                        <div className="col-3 mb-0">
                            <button type="button" id="phoneAuthBtn" className="btn btn-bd-light w-100 cfs-16 btn-auth" onClick={onSMSBtnClick}>인증</button>
                        </div>
                    </div>
                    </>
                ):(
                    <>
                    <div>
                        <label className="col-form-label label_text">휴대폰 번호</label>
                    </div>
           
                    <div className="d-flex">    
                        <div className="col-9 pe-1">
                            <input
                            type="tel"
                            className="form-control pt-2 pb-2 pe-0"
                            id="phone"
                            name="phone"
                            placeholder="휴대폰 번호를 입력해주세요" 
                            value={formValues.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required />
                        
                            <div className="invalid-feedback">{formErrors.phone}</div>
                        
                        </div>
                        <div className="col-3 mb-0">
                            <button type="button" id="phoneAuthBtn" className="btn btn-bd-light w-100 cfs-16 btn-auth" onClick={onSMSBtnClick}>인증</button>
                        </div>
                    </div>
                    </>
                )}
               
            </div>

            <div className="mb-1">
                <div className="invalid-feedback" id="fail-msg-phone">{formErrors.fail_msg_phone}</div>
            </div>

            {show ? (
            <div className="mb-2 pt-2 px-3 py-4 pb-2 bg_auth">
                <div className="row mb-1">
                    <p className="mt-2">입력한 휴대폰 번호로 인증번호가 발송되었습니다.<br/>발송된 6자리 인증번호를 입력해 주세요.</p>
                    <InputGroup name="authNo" title="인증번호 입력" type="text" placeholder="인증번호 (6자리)" formValue={formValues.authNo} formError={formErrors.authNo} handleChange={handleChange} handleBlur={handleBlur} />
                </div>
                <div className="row mb-1">    
                    <div className="col-sm-12">
                        <div className="d-flex justify-content-center">
                            {/*<button type="button" className="btn btn-primary mt-2" onClick={confirmAuthNoClick}>
                            인증확인
            </button>*/}
                        </div>
                        <div className="mt-2 mb-1 cfs-12 text-center">인증번호가 오지 않았다면? <u onClick={sendSMSClick} role="button">재발송 요청</u></div>
                    </div>
                </div>
            </div>
            ):(
                null
            )}

            <div className="row mb-1">    
                <div className="col-sm-12">
                    
                    <div className="d-flex justify-content-center px-4">
                        { phone_auth_date ? (
                            <>
                            <div className="col-6 ps-0 pe-1"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-2 mx-auto cfs-18 text-white" onClick={closeForm}>수정취소</button></div>
                            <div className="col-6 pe-0 ps-1"><button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-2 mx-auto cfs-18">수정완료</button></div>
                            </>
                        ):(
                            <>
                            <div className="col-6 ps-0 pe-1"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-2 mx-auto cfs-18 text-white" onClick={closeForm}>취소</button></div>
                            <div className="col-6 pe-0 ps-1"><button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-2 mx-auto cfs-18">완료</button></div>
                            </>
                        )}
                    </div>
                    
                </div>
            </div>
        </div>
        </form>
        
        { modalProgress && ( 
        <ModalPortal>
            <ProgressModal />
        </ModalPortal>
        )}

        </>
    );
}
export default ChangeMyInfoPhoneForm;