import React, { useEffect, useState, useContext } from 'react';

import ProgressModal from '../../modal/ProgressModal';
import ModalPortal from '../../../ModalPortal';
import axios from "axios";
import * as FCS from '../../../common/js/common_spc';
import { ApiContext } from '../../../Context';

let year = (new Date()).getFullYear();
let month = '00';
let day = '00';
const YEAR_SELECT = Array.from(new Array(100), (val, index) => year - index);
const MONTH_SELECT = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const DAY_SELECT = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

function ChangeMyInfoBirthdayForm({changeBirthdayFormClose, changeBirthdayFormCloseAfterGetData, birthday}) {
    
    const context = useContext(ApiContext);
    const USER_API = context.url;
    
    if (birthday==="") {
        birthday = "0000-00-00";
        year = '0000';
        month = '00';
        day = '00';
    }else{
        let strDate = birthday.replace(/\s/gi, '').replace(/년/gi, ',').replace(/월/gi, ',').replace(/일/gi, '');
    
        let arrDate = strDate.split(',');
        year = arrDate[0];
        month = (arrDate[1].length>1) ? arrDate[1] : '0'+arrDate[1];
        day = (arrDate[2].length>1) ? arrDate[2] : '0'+arrDate[2];
    }
    
  //let terms_agree = (formValues.agree1) ? 'Y' : 'N';
    
    const [yearSelected, setYearSelected] = useState(year);
    const [monthSelected, setMonthSelected] = useState(month);
    const [daySelected, setDaySelected] = useState(day);        

    const intialValues =  { birth: year + '-' + month + '-' + day, birthYear: year, birthMonth: month, birthDay: day, fail_msg: "" };
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [modalProgress, setModalProgress] = useState(false);
    
    

    

    const closeForm = () => {
        changeBirthdayFormClose();
    }
    const closeFormAfterData = () => {
        changeBirthdayFormCloseAfterGetData();
    }
    

    const changeBrithday = async() => {
        setModalProgress(true);
        let birth = `${formValues.birthYear}-${formValues.birthMonth}-${formValues.birthDay}`;
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'changeBirthday',
            birthday: birth,
           
        },{ withCredentials: true });
        
        if(response.data.success===true){
            setModalProgress(false);
            closeFormAfterData();
        }else{
            //실패시
            document.getElementById('fail-msg').style.display = 'block';
            formErrors.failMsg = response.data.error_msg;     
            feedBackErrorMsg('fail_msg', formErrors.failMsg); 
            setModalProgress(false);
        }
    }


    const handleBlur = (e) => {
        const { name } = e.target;
        //const { name, value } = e.target;
        if(name === 'birthYear'){ 
            //if(checked ==='age14'){            
            checkValidBirth();
            //}
        }
        if(name === 'birthMonth'){
            checkValidBirth();
        }
        if(name === 'birthDay'){
            checkValidBirth();
        }  
        
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

        if(name==='birthYear'){
            
            setYearSelected(value);
            
        }else if(name==='birthMonth'){
            
            setMonthSelected(value);
            
        }else if(name==='birthDay'){
            
            setDaySelected(value);
          
        }
        checkValidBirth();

        
        
    };
   
    const changeMyInfoBirthdaySubmitForm = (e) => {
      
        changeBrithday();
        e.preventDefault();
        e.stopPropagation();
    };
    const changeMyInfoBirthdayHandleSubmit = (e) => {
        
        const form = e.currentTarget;
        if (form.checkValidity() === false) { 
            //console.log('이름체크');          
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');                  
            setFormErrors(validate(formValues));    //Error state 값 갱신      
        }else{
           // setFormErrors(validate(formValues)); 
            const totError = Object.keys(formErrors).map(function(key) {
                var cnt = 0;
                if(formErrors[key].length>0){
                    cnt ++;
                }
                return cnt;
            }).reduce((a,b) => a+b, 0);

            if(totError<1) {
                changeMyInfoBirthdaySubmitForm(e);                   
            }else{
                e.preventDefault();
                e.stopPropagation();
            }
        }
        
    };
    const validate = (values) => { //휴면해제요청 submit일때만 체크
        let errors = {};
        
        // 출생년도 값이 없을시
        let birth = `${values.birthYear}-${values.birthMonth}-${values.birthDay}`;
        if(birth==='0000-00-00'){
            errors.birth = "";    
        }else{
            // if(values.birthYear==='0000'){
            //     errors.birth = "생년월일을 정확히 입력해주세요."
            // }else if(values.birthMonth==='00'){
            //     errors.birth = "생년월일을 정확히 입력해주세요."
            // }else if(values.birthDay==='00'){
            //     errors.birth = "생년월일을 정확히 입력해주세요."
            // }else if(FCS.check_Birthday(birth) === false){
            //     errors.birth = "생년월일을 정확히 입력해주세요. (14세이상가능)";
            // }else{
            //     errors.birth = ""; 
            // }
            checkValidBirth();
        }
        
        return errors;
    };

    const checkValidBirth = () => {
       
        let el_yy = document.getElementById('birthYear');
        let el_mm = document.getElementById('birthMonth');
        let el_dd = document.getElementById('birthDay');
        const el_birth = `${el_yy.value}-${el_mm.value}-${el_dd.value}`;
        if(el_birth==='0000-00-00')
        {
            formErrors.birth = "";
            
        }
        else
        {
            if (el_mm.value.length === 1) {
                el_mm = "0" + el_mm;
            }
            if (el_dd.value.length === 1) {
                el_dd = "0" + el_dd;
            }
            
        
            if (FCS.check_Birthday(el_birth) === false) {
                formErrors.birth = "생년월일을 정확히 입력해주세요. (14세이상가능)";
            } else {

                if (el_yy.value==='0000') {
                    el_yy.classList.add('is-invalid');       
                    formErrors.birth = "태어난 년도를 선택하세요.";
                }
                else if (el_mm.value==='00') {
                    el_mm.classList.add('is-invalid');       
                    formErrors.birth = "태어난 월을 선택하세요.";                
                }
                else if (el_dd.value==='00') {
                    el_dd.classList.add('is-invalid');       
                    formErrors.birth = "태어난 일(날짜)를 선택하세요.";
                }
                else
                {
                    el_yy.classList.remove('is-invalid');
                    el_mm.classList.remove('is-invalid');
                    el_dd.classList.remove('is-invalid');
                    formErrors.birth = "";
                    
                }
            }
        }
        feedBackErrorMsg('birth', formErrors.birth);
        
    }
   

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }

    useEffect(() => {
       
    }, [formErrors]);
   

    return (
        <>
        <form className="g-3 needs-validation" noValidate onSubmit={changeMyInfoBirthdayHandleSubmit}>
        <div className="mb-3">
           
           {/* <InputGroup name="new_name" title="생년월일 수정" type="text" placeholder="변경할 생년월일" formValue={formValues.new_name} formError={formErrors.new_name} handleChange={handleChange} handleBlur={handleBlur} />*/}

            <label className="col-form-label label_text pb-0">생년월일</label>
            <div className="row">
                <div className="col-4 mb-1 pe-0">
                    <select className="form-select cf-select invalid" aria-label="Default select example" id="birthYear" name="birthYear"
                    onChange={handleChange}
                    value={yearSelected}
                    onBlur={handleBlur} required>
                    <option value="0000">출생년도</option>  
                    {
                        YEAR_SELECT.map((year, idx) => {
                            return <option key={idx} value={year}>{year}년</option>
                        })
                    }
                    </select>
                </div>
                <div className="col-4 ps-2 pe-2">
                    <select className="form-select cf-select invalid" aria-label="Default select example" id="birthMonth" name="birthMonth"
                    onChange={handleChange}
                    value={monthSelected}
                    onBlur={handleBlur} required>
                    <option value="00">월</option>
                    {
                        MONTH_SELECT.map((month, idx) => {
                            return <option key={idx} value={month}>{month}월</option>
                        })
                    }      
                    </select>
                </div>
                <div className="col-4 ps-0">
                    <select className="form-select cf-select invalid" aria-label="Default select example" id="birthDay" name="birthDay"
                    onChange={handleChange}
                    value={daySelected}
                    onBlur={handleBlur} required>
                    <option value="00">일</option>
                    {
                        DAY_SELECT.map((day, idx) => {
                            return <option key={idx} value={day}>{day}일</option>
                        })
                    } 
                    </select>
                </div>
            </div>




        
            <div className="row col-12">
                { formErrors.birth &&
                    <>
                    <span className="is-invalid"></span>
                    <div className="invalid-feedback birth">{formErrors.birth}</div>
                    </>
                }
            </div> 

            <div className="mb-1">    
                <div className="col-sm-12">                    
                    <div className="d-flex justify-content-center px-4">
                        <div className="col-6 ps-0 pe-1"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-2 mx-auto cfs-18 text-white" onClick={closeForm}>수정취소</button></div>
                        <div className="col-6 pe-0 ps-1"><button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-2 mx-auto cfs-18">수정완료</button></div>
                    </div>
                </div>
            </div>
        </div>
        </form>
        
        { modalProgress && ( 
        <ModalPortal>
            <ProgressModal />
        </ModalPortal>
        )}

        </>
    );
}
export default ChangeMyInfoBirthdayForm;