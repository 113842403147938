import React from 'react';

function Footer() 
{
  return (
    <div
        id="gpFoot"
        className="py-3 d-flex flex-column align-items-center"
        role="contentinfo"
        aria-hidden="false"
      >
        <small className="txt_copyright"><a href="https://user.godpeople.com/policy/policy_terms.html" target="_blank" rel="noreferrer"><span className="text-info">이용 약관</span></a><i className="co_bar"></i><a href="https://user.godpeople.com/policy/policy_privacy.html" target="_blank" rel="noreferrer"><span className="text-info font-weight-bold">개인정보처리방침</span></a></small>
        <small className="txt_copyright text-info">Copyright © GODpeople.Ltd All rights reserved.</small>
    </div>
  );
}
export default Footer;