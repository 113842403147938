import React from 'react';

function NotFoundForm() {
    return (
        <>
            <form className="g-3 needs-validation" noValidate>
                죄송합니다.<br />
                페이지를 찾을 수 없습니다.
            </form>
        </>
       
    );
  }
  
  export default NotFoundForm;
