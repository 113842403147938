import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel } from 'swiper';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from "axios";
import 'swiper/swiper-bundle.css';

//import './Cartoon.css';

const useStyles = makeStyles({
    root: {
      minWidth: 320,
      border:'none',
      backgroundColor: '#5bb4dc'
    },
    rect: {
        borderRadius:10
    },
    bnr_form:{
        width:'100%',
        borderRadius:'10px'
    }
});

SwiperCore.use([Mousewheel]);
const Advs = () => {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {

        //console.log('TodayBible--->컴포넌트가 화면에 나타남');
        const SERVICES_API = "https://tongapi.godpeople.com/channel/_api_for_more.xxx";
        const getData = async () => {
            setIsLoading(true);

            const response = await axios.post(SERVICES_API,{
                task: 'get_advs_list',
                loc_id: 'login_page_600_280',
                order: 'rand'
            });
            if(response.data.success===true){
                setItems(response.data.list);   
            }

            setIsLoading(false);
        };
        getData();
    },[]);

    return(
        <>
            { isLoading ? (
                <>
                    <div className="box">

                        <Skeleton variant="rect" height={140} className={classes.rect} />
                    </div>
                </>
            ) : (
            <>
                <div className="box section_storebanner">
                    {/*<div className="title"><h2>크리스천과 교회를 위한 쇼핑</h2></div> */}
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        //navigation
                        pagination={{ clickable: true }} 
                        mousewheel={true} 
                        //onSlideChange={() => console.log('slide change')}
                        //onSwiper={(swiper) => console.log(swiper)}
                        className="section-swiper advs-swiper"
                        >
                        {items.map((itemData,index) => ( 
                            <SwiperSlide key={index}>
                                <div className="item_box">
                                    <div className="item_mw">
                                       
                                        <a href={itemData.url} className="thumb_a" alt="광고배너" target="_blank" rel="noopener noreferrer"><img src={itemData.image_url} className={`thumb ${classes.bnr_form}`} alt="배너 이미지"></img></a>
                                   
                                        
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </>
            )}
        </>
    );
}
export default Advs;