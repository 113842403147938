import React from 'react';

function Button({title}) {
    return (
      <>
          <button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-4 mx-auto cfs-18">{title}</button>
      </>
    );
  }
  
export default Button;
  
