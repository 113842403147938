import React, { useState, useCallback, useContext } from 'react';
import InputGroup from '../../common/InputGroup';
import ProgressModal from '../../modal/ProgressModal';
import ModalPortal from '../../../ModalPortal';
import axios from "axios";
import * as FCS from '../../../common/js/common_spc';
import { ApiContext } from '../../../Context';

function ChangeMyInfoPwdForm({changePwdFormClose, changePwdFormCloseAfterGetData}) {
    const intialValues =  { passwd: "", new_pw: "", new_pw2: "", fail_msg: ""};
    const [formValues, setFormValues] = useState(intialValues); 
    const [formErrors, setFormErrors] = useState({}); //각 입력 필드에 대한 오류를 보유
    const [modalProgress, setModalProgress] = useState(false);
    
    const context = useContext(ApiContext);
    const USER_API = context.url;

    const closeForm = () => {
        changePwdFormClose();
    }
    const closeFormAfterData = () => {
        changePwdFormCloseAfterGetData();
    }
    

    const changePasswd = async() => {
        setModalProgress(true);
        //let USER_API = "https://userapi.godpeople.com/";
        const response = await axios.post(USER_API,{
            task: 'changePasswd',
            passwd: formValues.passwd,
            new_pw: formValues.new_pw,
            srvc_id: 'user',
           
        },{ withCredentials: true });
        
        if(response.data.success===true){
            
            setModalProgress(false);
            closeFormAfterData();
        }else{
            //실패시
             
            document.getElementById('fail-msg').style.display = 'block';
            if(response.data.error_code===7321) {
                formErrors.failMsg = '기존 비밀번호가 일치하지 않습니다.';
            }else{
                formErrors.failMsg = response.data.error_msg;
            }
            
            feedBackErrorMsg('fail_msg', formErrors.failMsg); 
            setModalProgress(false);
        }
    }


    const handleBlur = (e) => {
        const { name } = e.target;
        //const { name, value } = e.target;
        if (name === 'passwd'){
            checkValidNewPwd();
        }
        if (name === 'new_pw'){
            checkValidNewPwd1();
        }
        if (name === 'new_pw2'){
            checkValidNewPwd2();
        }
    };

    const handleChange = useCallback(e => {
        
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      
    }, [formValues]);

    const changeMyInfoPwdSubmitForm = (e) => {
        //e.preventDefault();
        //e.stopPropagation();
        changePasswd();
        
    };
    const changeMyInfoPwdHandleSubmit = (e) => {
       
        const form = e.currentTarget;
        if (form.checkValidity() === false) {           
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');                  
            setFormErrors(validate(formValues));    //Error state 값 갱신      
        }else{
            //changeMyInfoPwdSubmitForm(e);  
            
            e.preventDefault();
            e.stopPropagation();

            if(formErrors.new_pw==='' && formErrors.new_pw2===''){
                
                changeMyInfoPwdSubmitForm(e);
            }

        }
        
    };
    const validate = (values) => { //휴면해제요청 submit일때만 체크
        let errors = {};
        
        if (!values.passwd) {
            errors.passwd = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.passwd) === false) {
            errors.passwd = "패스워드 형식이 유효하지 않습니다.";
        } 
        checkValidNewPwd();

        if (!values.new_pw) {
            errors.new_pw = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.new_pw) === false) {
            errors.new_pw = "8-16자의 영문자+숫자+특수문자를 모두 사용해야 합니다.";
        } 
        checkValidNewPwd1();

        if (!values.new_pw2) {
            errors.new_pw2 = "필수 항목입니다.";
        } else if (FCS.regex_PW(values.new_pw2) === false) {
            errors.new_pw2 = "8-16자의 영문자+숫자+특수문자를 모두 사용해야 합니다.";
        }
        checkValidNewPwd2();
        return errors;
    };

    const checkValidNewPwd = () => {
        let el = document.getElementById('passwd');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.passwd = "필수 항목입니다";
        } else {
            el.classList.remove('is-invalid'); 
            formErrors.passwd = "";     
        }
        feedBackErrorMsg('passwd', formErrors.passwd);
    }
    const checkValidNewPwd1 = () => {
        let el = document.getElementById('new_pw');      
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.new_pw = "필수 항목입니다";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.new_pw = "8-16자의 영문자+숫자+특수문자를 모두 사용해야 합니다.";         
        } else {
            el.classList.remove('is-invalid'); 
            formErrors.new_pw = "";     
        }
        feedBackErrorMsg('new_pw', formErrors.new_pw);
    }
    const checkValidNewPwd2 = () => {
        let el = document.getElementById('new_pw2');
        let el_new_pw1 = document.getElementById('new_pw');
        if (!el.value) {
            el.classList.add('is-invalid');       
            formErrors.new_pw2 = "필수 항목입니다";
        } else if (FCS.regex_PW(el.value) === false) {
            el.classList.add('is-invalid');
            formErrors.new_pw2 = "8-16자의 영문자+숫자+특수문자를 모두 사용해야 합니다.";         
        } else {
            if(el.value!==el_new_pw1.value){
                el.classList.add('is-invalid');
                formErrors.new_pw2 = "패스워드가 일치하지 않습니다";
            }else{
                el.classList.remove('is-invalid'); 
                formErrors.new_pw2 = "";
            }
        }
        feedBackErrorMsg('new_pw2', formErrors.new_pw2);
    }
  

    const feedBackErrorMsg = (values, msg) => {
        setFormErrors({ ...formErrors, [values]: msg});
    }
   

    return (
        <>
        <form className="g-3 needs-validation" noValidate onSubmit={changeMyInfoPwdHandleSubmit}>
        
        <div className="mb-3">   
                <InputGroup name="passwd" title="기존 비밀번호" type="password" placeholder="비밀번호 (8~16자리)" formValue={formValues.passwd} formError={formErrors.passwd} handleChange={handleChange} handleBlur={handleBlur} required={true} />
               
                <InputGroup name="new_pw" title="새로운 비밀번호" type="password" placeholder="비밀번호 (8~16자리)" formValue={formValues.new_pw} formError={formErrors.new_pw} handleChange={handleChange} handleBlur={handleBlur} required={true} />
           
                <div className="row mb-1">
                    <div className="col-sm-12">
                        <input
                        type="password"
                        className="form-control pt-2 pb-2"
                        id="new_pw2"
                        name="new_pw2"
                        placeholder="새로운 비밀번호 확인"
                        value={formValues.new_pw2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        />
                        
                        <div className="invalid-feedback">{formErrors.new_pw2}</div>
                        
                    </div>
                </div>

                {/*<InputGroup name="new_pw2" title="새로운 비밀번호 확인" type="password" placeholder="비밀번호 확인" formValue={formValues.new_pw2} formError={formErrors.new_pw2} handleChange={handleChange} handleBlur={handleBlur} />*/}
                     
                <div className="mb-1">
                    <div className="invalid-feedback" id="fail-msg">{formErrors.fail_msg}</div>
                </div>

                <div className="mb-1">    
                    <div className="col-sm-12">    
                        
                        <div className="d-flex justify-content-center px-4">
                            
                            <div className="col-6 ps-0 pe-1"><button type="button" className="btn w-100 btn-success bp-3 d-block mt-2 mx-auto cfs-18 text-white" onClick={closeForm}>수정취소</button></div>
                            <div className="col-6 pe-0 ps-1"><button type="submit" className="btn btn-primary bp-3 w-100 d-block mt-2 mx-auto cfs-18">수정완료</button></div>
                    
                        </div>
                        
                    </div>
                </div>
        </div>
        </form>
        
        { modalProgress && ( 
        <ModalPortal>
            <ProgressModal />
        </ModalPortal>
        )}

        </>
    );
}
export default ChangeMyInfoPwdForm;